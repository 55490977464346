import React  from 'react'
import { Menu, Divider, Layout, Button, Modal } from 'antd'
import { ExclamationCircleOutlined , AppstoreOutlined } from '@ant-design/icons';
import { Link, useHistory } from 'react-router-dom';
import { SvgPromotion }  from '../assets/svg/Promotion';
import { SvgTask } from '../assets/svg/SvgTask';
import { SvgGlass } from '../assets/svg/glass';

export const SideMenu = (props) => {
  const { Sider } = Layout;
  const { confirm } = Modal;
  const history = useHistory();
  const showLogoutConfirm = () => {
    confirm({
      title: 'Confirm logout',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure you want to log out?',
      onOk() {
          history.push('/logout');
          window.localStorage.removeItem("access-user");
          sessionStorage.clear();
      },
      onCancel() {},
    });
  }

  return (
    <>
    <Sider
            breakpoint="lg"
            collapsedWidth="0"
            onBreakpoint={broken => {
            }}
            onCollapse={(collapsed, type) => {
            console.log(collapsed, type);
            }}
        >
          <div className="logo" />
          <div className="left-nav">
            <Divider className="color-gray-driver"/>
            <Menu theme="dark" mode="inline" defaultSelectedKeys={[props.index]}>
                <Menu.Item key="1" icon={<SvgPromotion style={{ fontSize: '1.2rem', color: '#fff' }} />}>
                  <Link to="/">Promotion banner</Link>
                </Menu.Item>
                <Menu.Item key="2" icon={<AppstoreOutlined style={{ fontSize: '1.2rem', color: '#fff' }}  /> }>
                  <Link to="/categories">Content categories</Link>
                </Menu.Item>
                <Menu.Item key="3" icon={<SvgTask style={{ fontSize: '1.2rem', color: '#fff' }} />}>
                  <Link to="/task">Tasks</Link>
                </Menu.Item>
                <Menu.Item key="4" icon={<SvgGlass style={{ fontSize: '1.2rem', color: '#fff' }} />}>
                  <Link to="/reports" style={{ marginLeft: '10px' }}>Reports</Link>
                </Menu.Item>
            </Menu>
          </div>
        </Sider>
        <div style={{marginLeft:20,position:'fixed',bottom:32}}  className="logout_btn">
          <Button type="primary" className="LogOut" size="large" onClick={showLogoutConfirm}>Logout</Button>
        </div>
        </>
  )
      
}