// const devBaseURL = 'http://localhost:3000/api';
const devBaseURL = '';
// const devBaseURL = 'http://10.237.188.20:8080'; 

const preUrl = '/o/meta/cms';
export const BASE_URL = devBaseURL;

export const TIMEOUT = 90000;

const DEFAULT_APIS = {
    getLogin:"/first/getLogin",
    fetchPwdConfirm:"/modifyPwd",
    getCategoriesList: "/getCategory",
    getIndustryList : "/getIndustry",
    saveCategories : "/updateCategory",
    fetchCanRemove: "/checkCategory",
    fetchFileList: "/getBanner",
    saveFileList: "/updateBanner",
    fetchAllTask: "/getAllTask",
    modifyTaskStatus: "/updateTaskStatus",
    deleteTask: "/deleteTask",
    fetchSegmentList: "/getSegment",
    fetchAllCategoryList: "/getAllCategory",
    saveTask: "/addTask",
    getTask: "/getTask",
    updateTask: "/updateTask",
    freshToken:"/freshToken",
    checkBusinessId:"/checkTask",
    downloadFiles:"/task/link/files",
    richImg:"/upload/file",
}
const MOCK_APIS = {
    get_list: '../mock/list.json',
   
  };

export const getEnv=() => {
   // return environment.production;
}

export const getEndpoint=(name)=> {
    return BASE_URL + preUrl + DEFAULT_APIS[name];
    // return ENV.baseUrl + path;
}
export const getMockEndpoint=(name)=> {
    return BASE_URL + preUrl + MOCK_APIS[name];
    // return ENV.baseUrl + path;
}
export const getBackEndDomain= () => {
   // return environment.apiUrl;
}