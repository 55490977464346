import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { fetchLogin } from './loginSlice'
import { Button ,Form, Input, Spin } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import {showErrorModal} from './../../components/Modal'
import './login.scss';




export const Login = () => {
  //Request params
    const dispatch = useDispatch();
    const canSave = true;
    const [account, setAccount] = useState('');// eslint-disable-line no-unused-vars
    const [pwd, setPwd] = useState('');// eslint-disable-line no-unused-vars
    const onAccountChanged = e => setAccount(e.target.value);
    const onPwdChanged = e => setPwd(e.target.value);
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);



    const onFinish = (values) => {
      onSavePostClicked(values);
    };
    const onFinishFailed = (errorInfo) => {
    }

    const onSavePostClicked = async (values) => {
      setIsLoading(true);
      dispatch(fetchLogin(values)).then(res => {
          setIsLoading(false);
          if(res.payload && parseInt(res.payload.code)=== 0){     
            if(res.payload.data && res.payload.data.firstFlag ==='Y'){
              history.push('/confirm')
            } else {
              history.push('/')
            }       
            
          }else{
            const config = {
              title: (<><span style={{"fontWeight":"bold"}} className='font-color-error' >Error Message</span></>),
              content: 'Incorrect username and password combination, please try again.'
            }
            showErrorModal(config);
         }
          
      });
    }

    return(
      
        <div className="login login_bg">
            <Content>
            <div className="login_wrapper">
              <Content>
              <div className="logo_title">
                  <div className="title"><div className="logo"></div></div>
                  <div className="subtitle">CMS for WeChat mini program</div>
                </div>
              </Content>
              <Spin spinning={isLoading} delay={500}>
              <Form
                    name="loginForm"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    layout="vertical"
                  >
                    <Form.Item
                      label="Username"
                      name="account"
                      rules={[
                        {
                          required: true,
                          message: 'Please fill username before login.',
                        },
                      ]}
                    >
                      <Input placeholder="Input username here" onChange={onAccountChanged}/>
                    </Form.Item>

                    <Form.Item
                      label="Password"
                      name="pwd"
                      rules={[
                        {
                          required: true,
                          message: 'Please fill password before login.'
                        }
                      ]}
                    >
                      <Input.Password placeholder="Input password here"  onChange={onPwdChanged}/>
                    </Form.Item>

                    <Form.Item >
                      <div className="login_btn_wrapper">
                      <Button type="primary" className="login_btn"  htmlType="submit" disabled={!canSave}>
                        Login
                      </Button>
                      </div>
                    </Form.Item>
                  </Form>
                  
              
                </Spin>
                  </div>
              </Content>
        </div>
    )
}