import React, { useState } from 'react';
import { Button, Layout, Divider, Typography, Spin } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { SideMenu } from '../../components/Menus';
import { get } from '../../api/axios'
import { getEndpoint } from '../../api/config'
import { showErrorModal } from './../../components/Modal'
import './reports.scss';

export const Reports = () => {
    const { Content } = Layout;
    const { Title } = Typography;
    const [isLoading, setIsLoading] = useState(false);

    const downloadFlie = () => {
        setIsLoading(true);
        const response = get(getEndpoint('downloadFiles'),{},'blob').then(res => {
            if (res && res.status === 200) {
              const url = window.URL.createObjectURL(new Blob([res.data]));
              const link = document.createElement('a'); //创建a标签
              link.style.display = 'none';
              link.href = url; // 设置a标签路径
              link.download = 'Mini-program Task Report.xlsx'; //设置文件名， 也可以这种写法 （link.setAttribute('download', '文件名.xlsx');
              document.body.appendChild(link);
              link.click();
              URL.revokeObjectURL(link.href); // 释放 URL对象
              document.body.removeChild(link);
              setIsLoading(false);
            } else {
                const config = {
                    title:'Error Draft saved',
                    content: 'This task saved as draft failed.'
                }
                showErrorModal(config);
                setIsLoading(false);
            }
        })
    }

    return (
        <>
            <Layout>
                <SideMenu index='4'></SideMenu>
                <Layout>
                    <Content style={{ padding: '1.5rem' }}>
                        <Spin
                            wrapperClassName="self-spin-wrapper"
                            size="large"
                            spinning={isLoading}
                        >
                            <div className="site-layout-background content-div categories">

                                <Content style={{ margin: '1.5rem 1rem 0' }} className="reports-content">
                                    <Content style={{ margin: '.6rem 0' }}>
                                        <div className="flexBetweenBar">
                                            <Title level={2}>Reports</Title>
                                        </div>
                                    </Content>
                                    <Divider />

                                    <Content style={{ margin: '2rem 1rem 0 0' }}>
                                        <div className='report-tip'>Please click the download button bellowed to generate the report in Excel format.</div>
                                        <Button type="primary" htmlType="submit" icon={<DownloadOutlined style={{ fontSize: '1rem', color: '#fff' }} />} onClick={downloadFlie} > Download reports</Button>
                                    </Content>
                                </Content>
                            </div>
                        </Spin>
                    </Content>
                </Layout>
            </Layout>
        </>
    )
}