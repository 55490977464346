import React from 'react'
import {Redirect,Route} from "react-router-dom"

export const  MyRoute = (props) =>{
    let user = JSON.parse(localStorage.getItem('access-user'));

    return (
        <div>
            {
                user ? <Route {...props}></Route>:
                <Redirect to="/login"></Redirect>
            }
        </div>
    )
}