import React, { useState, useCallback, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Select , Input, Table, Layout, Divider, Typography, Popconfirm, Form, Space, Spin} from 'antd';
import { selectAllCategories, fetchCategoriesList, categorieAdded, categorieEdit, categorieRemove,fetchCanRemove , categorieMoveRow, fetchIndustryList, selectIndustries, saveCategories} from './categoriesSlice'
import { PlusOutlined } from '@ant-design/icons/lib';
import {  FormOutlined, DeleteOutlined } from '@ant-design/icons';
import {SideMenu} from '../../components/Menus';
import { DragableBodyRow } from '../../components/dragTable';
import { DndProvider} from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import {showErrorModal, showSuccessModal,} from './../../components/Modal'
import update from 'immutability-helper';
import './categories.scss';

const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={[
              {
                required: true,
                message: `Cannot add blank item.`,
              },
              {
                message: 'Input new sub-category should less than 10 characters!',
                pattern: new RegExp(/^.{1,10}$/)
              }
              // {
              //   pattern: new RegExp(/^.{0,10}$/),
              //   warningOnly: true,
              //   message: 'The title is too long to fully displayed.' 
              // }
            ]}
          >
            <Input placeholder="Input new Sub-category title here , less than 10 characters"/>
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };  
export const Categories = () => {
    const { Content } = Layout;
    const { Title, Text } = Typography;
    const { Option } = Select;
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [editingKey, setEditingKey] = useState('');
    const isEditing = (record) => record.key === editingKey;
    const industryStatus = useSelector(state => state.industries.status);
    const [isLoading, setIsLoading] = useState(true);
    const FirstIndustryId = useSelector( state => state.industries.FirstIndustryId)
    const [industryId, setIndustryId] = useState(FirstIndustryId);
    const [addMode, setAddMode] =  useState(false);
    useEffect(() => {
      if (industryStatus === 'idle') {
          dispatch(fetchIndustryList()).then(res =>{ 
            setIndustryId(res.payload.data.dataList[0].industryId);
            dispatch(fetchCategoriesList({industryId: res.payload.data.dataList[0].industryId})).then(res=>{
              setIsLoading(false);
            });
          });
      }else{
        setIsLoading(true);
        //Did not need call Industry List
        dispatch(fetchCategoriesList({industryId: industryId})).then(res=>{
          setIsLoading(false);
        });
      }
      return () => {
        dispatch(fetchCategoriesList({industryId: industryId}));
      }
     // eslint-disable-next-line
    },[])
    const [tableConfig,SetTableConfig] = useState({
      row: DragableBodyRow,
      cell: EditableCell,
    });

    
    useEffect(() => {
      if(editingKey !== ''){
       SetTableConfig({
            
            cell: EditableCell,
          });
      }else{
        SetTableConfig({
          row: DragableBodyRow,
          cell: EditableCell,
        });
      }
      
    },[editingKey])
    const categories =  useSelector(selectAllCategories).categories;
    const industryList  =  useSelector(selectIndustries).industries;
    const industryOptions = industryList.map((industry)=>(
      <Option  key={industry.industryId} value = {industry.industryId}> {industry.industryTitle}</Option>    
    ));
    
    const edit = (record) => {
        form.setFieldsValue({
          categoryTitle: '',
          ...record,
        });
        setEditingKey(record.key);
    };
    const cancel = async() => {
      if(addMode){
        setAddMode(false);
        dispatch(categorieRemove(categories.filter((item) => item.key !== editingKey)));
        setEditingKey('');
      }else{
        setEditingKey('');
      }
    
    };
  
    const onRowFun =  (record, index) => (editingKey ===''?{ index, moveRow }:{});
    const save = async (key) => {
        try {
          const row = await form.validateFields();
          const newData = [...categories];
          const index = newData.findIndex((item) => key === item.key);
          if (index > -1) {
              const item = newData[index];
              newData.splice(index, 1, { ...item, ...row });
              dispatch(categorieEdit(newData));
              setEditingKey('');
          } else {
              newData.push(row);
              dispatch(categorieEdit(newData));
              setEditingKey('');
          }
          setAddMode(false);
        } catch (errInfo) {
          console.log('Validate Failed:', errInfo);
        }
    };
  
    const columns = [
    {
        title: 'Title',
        dataIndex: 'categoryTitle',
        key: 'categoryTitle',
        editable: true,
    },
    {
        title: 'operation',
        dataIndex: 'operation',
        width: '15%',
        render: (_, record) =>
        {

            const editable = isEditing(record);
            return editable ? (
              <span>
                <a
                  href="#!"
                  onClick={() => save(record.key)}
                  style={{
                    marginRight: 8,
                  }}
                >
                  Done
                </a>
                <Popconfirm title="Sure to cancel?" onConfirm={cancel} okText="OK" cancelText="Cancel
                ">
                  <a>Cancel</a>
                </Popconfirm>
              </span>
            ) : (
                <div>
                    <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
                      <FormOutlined /> &nbsp;
                        
                    </Typography.Link>
                    <Popconfirm title="Sure to delete? You cannot undo this action." onConfirm={() => {handleDelete(record)}}>
                    <a><DeleteOutlined /></a>
                    </Popconfirm> 
                </div>
            );   
                         
            
        }
    }];
    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
          return col;
        }
        return {
          ...col,
          onCell: (record) => ({
            record,
            inputType: 'text',
            dataIndex: col.dataIndex,
            title: col.title,
            editing: isEditing(record),
          }),
        };
      });
    async function  handleIndustryChange(e)  {
        setIsLoading(true);
        setIndustryId(e);
        await dispatch(fetchCategoriesList({industryId: e}));
        setIsLoading(false);
    }
    const handleDelete = (record) => {
        const dataSource = [...categories];
        const key = record.key;
        if(record.isNew){
            dispatch(categorieRemove(dataSource.filter((item) => item.key !== key)));
                      
            const config = {
              title:'Remove successfully',
              content: 'The sub-category has removed from list.'
            }
            showSuccessModal(config);
            return false;
        }
        if(record.categoryId){
            setIsLoading(true);
            dispatch(fetchCanRemove({categoryId: record.categoryId})).then(res => {
              if(res.payload && parseInt(res.payload.code)=== 0){
                  if(res.payload.data && res.payload.data && res.payload.data.flag === 'N'){
                      dispatch(categorieRemove(dataSource.filter((item) => item.key !== key)));
                     
                        const config = {
                          title:'Remove successfully',
                          content: 'The sub-category has removed from list.'
                        }
                        showSuccessModal(config);
                  }else{
                    const config = {
                      title:'Tasks are related to current sub-category',
                      content: 'Cannot delete this sub-category as it’s related to active tasks.'
                    }
                    showErrorModal(config);
                  }
                 
                }else{
                  const config = {
                    title:'Error Message',
                    content: 'Please,Contact the administrator'
                  }
                  showErrorModal(config);
              }
              setIsLoading(false);
            });
        }else{
          const config = {
                    title:'Error Message',
                    content: 'Please,Contact the administrator'
                  }
                  showErrorModal(config);
          ///dispatch(categorieRemove(dataSource.filter((item) => item.key !== key)));
        }
    };
  

    
   
    const handleAdd = () => {
        const newData = {
            categoryTitle: ``,
            industryId : industryId,
            isNew : true
        };
        form.resetFields();
        const newCat = dispatch(categorieAdded(newData));
        setAddMode(true);
        setEditingKey(newCat.payload.key);
    };

      
    const moveRow = useCallback(
      (dragIndex, hoverIndex) => {
        const dragRow = categories[dragIndex];
        const newData =   update(categories, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow],
          ],
        });
        dispatch(categorieMoveRow(newData));
      
      },
      [categories],
    );

    const onPublishClicked = () => {
      const param = {
        categories: categories,
        industryId:  industryId
      }
      setIsLoading(true);
      dispatch(saveCategories(param)).then(res => {
        if(res.payload && parseInt(res.payload.code) === 0){     
          const config = {
            title:'Updated successfully',
            content: 'Changes are successfully updated. '
          }
          setIsLoading(false);
          showSuccessModal(config);
        }else{
          const config = {
            title:'Error Message',
            content: 'The password and confirmation do not match'
          }
          
          setIsLoading(false);
          showErrorModal(config);
       }
      });
    }
    return(
        <>
         
            <Layout>
            <SideMenu
              index='2'
            >
            </SideMenu>
            <Layout>
                <Content style={{  padding: '1.5rem' }}>      
                <Spin
                  wrapperClassName="self-spin-wrapper"
                  size="large"
                  spinning={isLoading}
                  > 
                <div className="site-layout-background content-div categories">
                
                    <Content style={{ margin: '1.5rem 1rem 0' }}>  
                      <Content style={{ margin: '.6rem 0'}}>      
                          <div className="flexBetweenBar">
                          <Title level={2}>Content categories management</Title>
                          </div>
                      </Content> 
                      <Divider />
                      <Title level={5}>Select main category</Title>
                      <div className="img_list">

                         {/* <Select value={ userId } onChange={handleChange}
                            placeholder="Select a option and change input text above"
                            allowClear >
                            <Option value=""></Option>
                            {usersOptions}
                          </Select> */}
                          <Select value={ industryId } disabled={editingKey !== ''}  size='large' style={{ width: 210 }} onChange={handleIndustryChange}>
                              {industryOptions}
                          </Select>
                      </div>
                      <Content style={{ margin: '2rem 1rem 0 0' }}>       
                          <div className="categories_table">                    
                          <Title level={5}>Manage sub-category</Title>
                          <DndProvider  backend={HTML5Backend}>
                          <Form form={form} component={false}>
                          <Table
                              components={{
                                  body: tableConfig,
                                }}
                              rowClassName="editable-row"
                              pagination={false}
                              columns={mergedColumns}
                              dataSource={categories}
                              forceRender={true}
                              onRow = {onRowFun}
                          />
                          </Form>
                        </DndProvider>
                        </div>
                        <Space direction="vertical">
                        <Text type="secondary">Drag to change sequence</Text>
                        <Button
                              onClick={handleAdd}
                              type="primary"
                              size="large"
                              style={{
                                  marginBottom: 16,
                                  marginTop: 16,
                              }}
                              icon={<PlusOutlined />}
                              disabled={editingKey !== ''}
                              >
                              Add another section
                        </Button>
                        </Space>
                      </Content>
                      <Divider />
                      <Content style={{ margin: '.6rem 0',padding:'0 0 1.8rem 0' }}>         
                          <div className="flexBetweenBar">
                              <Button type="primary" size="large" disabled={editingKey !== ''} onClick={onPublishClicked}>Confirm and publish</Button>
                          </div>
                      </Content> 
                    </Content>
                </div>
                </Spin>
                </Content>
            </Layout>
            </Layout>                 
        {/* <Form  labelCol= {{ span: 2 }}  wrapperCol={{ span: 8 }}>
            <Form.Item
                label="key"
                name="searchKey"
                    rules={[{ required: true, message: 'Please input searchKey!' }]} >
            <Input type="searchKey"
                    name="searchKey"
                    value={key}
                    onChange={onKeyChanged}  />
            
            <Button onClick={() => history.push({pathname: "/post", state: {id: key} }) }>
                click
            </Button>
            </Form.Item>
        </Form> */}
        </>
        
    )
}