import * as React from "react";

export const SvgPromotion = (props) => (
  <svg
    width="1rem"
    height="1rem"
    viewBox="0 0 12 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.477 11.888 6 11.495l-.477.393-3.864 3.185a.25.25 0 0 1-.409-.193V1A.25.25 0 0 1 1.5.75h9a.25.25 0 0 1 .25.25v13.88a.25.25 0 0 1-.409.193l-3.864-3.185Z"
      stroke="#fff"
      strokeWidth={1.5}
    />
  </svg>
);