import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { message } from 'antd'
import { post} from '../../api/axios'
import {  getEndpoint} from '../../api/config'

const initialState = {
  fileList: [
    ],
  status: 'idle',
  error: null
}


export const fetchFileList = createAsyncThunk('fileList/fetchFileList', async () => {
  const response = await post(getEndpoint('fetchFileList'))
  return response.data;
})
export const saveFileList = createAsyncThunk('categories/saveFileList', async (params) => {
  let user = JSON.parse(window.localStorage.getItem('access-user'));
  const newParams = {
    "account": user.account,
    "dataList": params.fileList,
  }
  const response = await post(getEndpoint('saveFileList') , newParams);
  return response.data;
})

const promotionalSlice = createSlice({
  name: 'fileList',
  initialState,
  reducers: {
    fetchFileList: {
      reducer(state, action) {
        state.fileList = action.payload;
      }
    },
    changeFileListyStatus: {
      reducer(state, action) {
        state.status = "idle";
      },
    },
    changeFileList: {
      reducer(state, action) {
        //state.fileList = action.payload;
      },
    },
    saveFileList: {
      reducer(state, action) { 
        state.status = "idle";
      },
    }
  },
  extraReducers: {
    [fetchFileList.pending]: (state, action) => {
      state.status = 'loading'
    },
    [fetchFileList.fulfilled]: (state, action) => {
      state.status = 'succeeded'
      if(action.payload  && parseInt(action.payload.code) === 0){
          if(action.payload.data){
            state.fileList = action.payload.data.dataList;
            let array = [];
            state.fileList.map((item) => {
              return array.push(Object.assign({}, item, { uid: item.bannerUuid }))
            })
            state.fileList = array;
          }
          // else{
          //   state.fileList =  state.fileList;
          // }
      }else{
        message.error('Network problems');
      }
    },
    [fetchFileList.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.error.message
    },
    
  }
})
export const { changeFileList, changeFileListyStatus } = promotionalSlice.actions

export default promotionalSlice.reducer

export const selectFileList = state => state.fileList
//export const selectAllCategories = state => state.categories