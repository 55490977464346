import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { post} from '../../api/axios'
import {  getEndpoint} from '../../api/config'

const initialState = {
  taskList: [
    ],
  segmentList:[],
  categoryList: [],
  status: 'idle',
  segmentStatus: 'idle',
  categoryStatus: 'idle',
  error: null,
  taskInfo:{}
}

export const getTask = createAsyncThunk('tasks/getTask', async (params) => {
  //let user = JSON.parse(window.localStorage.getItem('access-user'));
  const response = await post(getEndpoint('getTask'),params);
  sessionStorage.setItem(response.data.data.taskUuid, JSON.stringify(response.data.data));
  return response.data;
})
export const updateTask = createAsyncThunk('tasks/updateTask', async (params) => {
  let user = JSON.parse(window.localStorage.getItem('access-user'));
  const account = {"account": user.account}
  const newParams = {
    ...params,...account
  }
  const response = await post(getEndpoint('updateTask'),newParams);
  return response.data;
})
export const checkBusinessId = createAsyncThunk('tasks/checkBusinessId', async (params) => {
  const response = await post(getEndpoint('checkBusinessId'),params);
  return response.data.data;
  //return {code:0,flag:'Y'}
})

export const saveTask = createAsyncThunk('tasks/saveTask', async (params) => {
  let user = JSON.parse(window.localStorage.getItem('access-user'));
  const account = {"account": user.account}
  const newParams = {
    ...params,...account
  }
  const response = await post(getEndpoint('saveTask'),newParams);
  return response.data;
})

export const fetchSegmentList = createAsyncThunk('tasks/fetchSegmentList', async () => {
  const response = await post(getEndpoint('fetchSegmentList'));
  return response.data;
})
export const fetchAllCategoryList = createAsyncThunk('tasks/fetchAllCategoryList', async () => {
  const response = await post(getEndpoint('fetchAllCategoryList'));
  return response.data;
})

export const fetchTaskList = createAsyncThunk('tasks/fetchAllTask', async () => {
  const response = await post(getEndpoint('fetchAllTask'));
  return response.data;
})

export const modifyTaskStatus = createAsyncThunk('tasks/modifyTaskStatus', async (params) => {
  let user = JSON.parse(window.localStorage.getItem('access-user'));
  const newParams = {
    "account": user.account,
    "taskUuid": params.row.taskUuid,
    "status" : params.status
  }
  const response = await post(getEndpoint('modifyTaskStatus'),newParams)
  return {...response.data,...newParams};
})

export const deleteTask = createAsyncThunk('tasks/deleteTask', async (params) => {
  let user = JSON.parse(window.localStorage.getItem('access-user'));
  const newParams = {
    "account": user.account,
    "taskUuid": params.taskUuid,
  }
  const response = await post(getEndpoint('deleteTask'),newParams);
  return {...response,key: params.key};
})

// upload richtext img
export const uploadRichImg = createAsyncThunk('tasks/uploadRichImg', async (params) => {
  const response = await post(getEndpoint('richImg'),params);
  return response.data;
})

// export const saveFileList = createAsyncThunk('categories/saveFileList', async (params) => {
//   console.log("###saveFileList params",params);
//   let user = JSON.parse(window.localStorage.getItem('access-user'));
//   const newParams = {
//     "account": user.account,
//     "dataList": params.fileList,
//   }
//   const response = await post(getEndpoint('saveFileList') , newParams);
//   return response.data;
// })

const taskSlice = createSlice({
  name: 'tasks',
  initialState,
  reducers: {
    modifyTaskStatusReducer(state, action) {
        // const {id, title, content } = action.payload;
        // const postItem = state.posts.find(post =>post.id === id);
        // if( postItem ){
        //   postItem.title = title
        //   postItem.content = content
        // }
        state.taskList = action.payload;
    },
    changeTaskListyStatus(state, action){
      state.status = "idle";
    }
  },
  extraReducers: {
    [fetchTaskList.pending]: (state, action) => {
      state.status = 'loading'
      state.taskLis = [];
    },
    [fetchTaskList.fulfilled]: (state, action) => {
      state.status = 'succeeded'
      //state.taskList = action.payload.data.dataList;
      state.taskList = action.payload.data.dataList;
      // if(action.payload  && parseInt(action.payload.code) === 0){
      //     if(action.payload.data){
      //       state.taskList = action.payload.data.dataList
      //     }else{
      //       state.taskList =  state.taskList;
      //     }
      // }else{
      //   message.error('Network problems');
      // }
    },
    [fetchTaskList.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.error.message
    },
    [modifyTaskStatus.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      const {taskUuid, status} = action.payload;
      const taskItem = state.taskList.find(task =>task.taskUuid === taskUuid);
      if( taskItem ){
        taskItem.status = status
      }
    },
    [deleteTask.fulfilled]: (state, action) => {
      const key = action.payload.key;
      state.status = 'succeeded'
      if(parseInt(action.payload.data.code) === 0){
        state.taskList = state.taskList.filter((item) => item.key !== key)
      }
    },
    [fetchSegmentList.pending]: (state, action) => {
      state.segmentStatus = 'loading'
    },
    [fetchSegmentList.fulfilled]: (state, action) => {
      state.segmentStatus = 'succeeded'
      if(action.payload && action.payload.data){
        state.segmentList = action.payload.data.dataList
      }
     
    },
    [fetchSegmentList.rejected]: (state, action) => {
      state.segmentStatus = 'failed'
      state.error = action.error.message
    },
    [fetchAllCategoryList.pending]: (state, action) => {
      state.categoryStatus = 'loading'
    },
    [fetchAllCategoryList.fulfilled]: (state, action) => {
      state.categoryStatus = 'succeeded'
      if(action.payload && action.payload.data){
        state.categoryList = action.payload.data.industryList;
      }
     
    },
    [fetchAllCategoryList.rejected]: (state, action) => {
      state.categoryStatus = 'failed'
      state.error = action.error.message
    },
    [saveTask.fulfilled]: (state, action) => {
      state.status = 'succeeded'
   },
    [getTask.fulfilled]: (state, action) => {
      state.status = 'succeeded'
      if(action.payload && action.payload.data){
        state.taskInfo = action.payload.data;
        const  formateObj = {coverImageUrl:state.taskInfo.imgUrl};
        state.taskInfo = {...state.taskInfo,...formateObj};
      }   
    },
    
    
  }
})
export const { changeTaskList, changeTaskListyStatus } = taskSlice.actions

export default taskSlice.reducer

export const selectTask = state => state.tasks

export const selectTaskById = (state, taskId) =>
  state.tasks.taskInfo
