
import React from 'react';

import { Redirect } from 'react-router';
import '../src/assets/css/index.scss'
import  Home  from './features/home';
// import  Home  from './components/Page';
import { Login } from './features/login';
import { LoginComfirm } from './features/login/confirm';
import { Categories } from './features/categories';
import { Reports } from './features/reports';
import { Task } from './features/task';
import { CreateTask } from './features/task/createTask';
import { EditTask } from './features/task/editTask';
import { Logout } from './features/login/logout';
import { MyRoute } from './features//util/MyRoute';
import { ErrorPage } from './404';
import { ErrorPage500 } from './500';

import {
  BrowserRouter as Router,
  Route,
  Switch,
} from "react-router-dom";



function App() {
  return (
    <div className="App" id="app">
      <Router>
        <Switch>
          <MyRoute exact path="/" component={Home} />
          <Route exact path="/login" component={Login}/>
          <Route exact path="/logout" component={Logout}/>
          <MyRoute exact path="/confirm" component={LoginComfirm}/>
          <MyRoute exact path="/categories" component={Categories}/>
          <MyRoute exact path="/reports" component={Reports}/>
          <MyRoute exact path="/task" component={Task}/>
          <MyRoute exact path="/createTask" component={CreateTask}/>
          <MyRoute exact path="/404" component={ErrorPage}/>
          <MyRoute exact path="/500" component={ErrorPage500}/>
          <MyRoute exact path="/task/:taskId" component={EditTask}/>
          <Redirect from="*" to="/404" ></Redirect>
          
          {/* 精准匹配exact 
          <Route exact path="/mine/:id?/:name?" component={Mine} /> // 问号非必传，否则404
          {/* 更精准匹配exact strict 
          <Route exact strict path="/about" component={About} />
          <Route exact strict path="/shop" component={Shop} />
          {/* 简形式 
          <Route path="/demo" render={() => <div>demo</div>} />
          {/* 重定向 
          <Redirect from="/my" to="mine" /> */}
          
        </Switch>
      </Router>
    </div>
  );
}

export default App;
