import React from 'react'
import { Layout} from 'antd'

export const ErrorPage500 = () => {
    const { Content } = Layout;

    return(
        <>
            <Layout>
            <Layout>
                <Content style={{ margin: '24px 16px 0' }}>         
                <div className="site-layout-background content-div categories">
                        ERROR page 500
                </div>
                The server encountered an internal error or misconfiguration and was unable to complete your request.
                </Content>
            </Layout>
            </Layout>
    
    
        </>
        
    )
}