
//鼠标最后的操作时间
let lastTime = new Date().getTime();
const checkTime = ()=>{
    //设置超时时间 30min
    let timeOut =  30 * 60 * 1000;
    let ICE_CONTAINER = document.getElementById('app');
    //当前时间
    let currentTime = new Date().getTime(); 
    //flag判断是否为登录页
    let timeType = false;
    ICE_CONTAINER.addEventListener('mousemove', function() {
        // 更新最后的操作时间
        lastTime = new Date().getTime();
        //console.log('鼠标移动了',currentTime - lastTime);
    })
    //获取当前路径   是否在登录页
    let arry = window.location.href.split('/');
    let endWith = arry[arry.length-1];
    timeType = endWith ==='login'? true : false;
    // console.log("是否是登录页面",timeType);
    // console.log(currentTime - lastTime,timeOut);
    //判断停留页面是否超时
    if (currentTime - lastTime > timeOut) {
        if (!timeType) {
            // 超时操作
            console.log('长时间未操作，请重新登录!');
            window.location.href = '/login';
            localStorage.removeItem("access-user");
            lastTime = new Date().getTime();
            sessionStorage.clear();
        } else {
            lastTime = new Date().getTime();
        }
    }
}

const checkTimeout =  () => {
    window.setInterval(checkTime, 5000);
}
export default checkTimeout;