import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux'
import store from './common/store'
import 'antd/dist/antd.css';
import  checkTimeout from './common/timeout';
import  checkTokenTimeout from './common/checkToken';

ReactDOM.render(
    <Provider store={store}>
      <App />
    </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
checkTimeout();
reportWebVitals();
checkTokenTimeout();
