import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
    Button,
    Cascader,
    Divider,
    Form,
    Input,
    InputNumber,
    Layout,
    message,
    Modal,
    Radio,
    Select,
    Space,
    Spin,
    Typography,
    Upload
} from 'antd';

import {
    ExclamationCircleOutlined,
    LoadingOutlined,
    MinusCircleOutlined,
    PlusOutlined,
    UploadOutlined
} from '@ant-design/icons';
import {useHistory} from 'react-router-dom';
import {SideMenu} from '../../../components/Menus';
import {
    changeTaskListyStatus,
    checkBusinessId,
    fetchAllCategoryList,
    fetchSegmentList,
    saveTask,
    selectTask,
    uploadRichImg
} from './../taskSlice';
import {showErrorModal, showSuccessModal} from '../../../components/Modal';
import './createTask.scss';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const {Option} = Select;


export const CreateTask = () => {
    //let isEditForm = false;
    const reactQuillRef = useRef([])
    const currentIndex = useRef(0);
    const {Content} = Layout;
    const {Title} = Typography;
    const {TextArea} = Input;
    const {confirm} = Modal;
    //Set radio
    const [contentType, setContentType] = useState(1);
    const [localTaskHide, setLocalTaskHide] = useState(false);
    const [pdfHide, setPdfHide] = useState(true);
    const [weChatHide, setWeChatHide] = useState(true);
    const dispatch = useDispatch();
    const categoryStatus = useSelector(state => state.tasks.categoryStatus);
    const segmentStatus = useSelector(state => state.tasks.segmentStatus);
    const [referenceType, setReferenceType] = useState(1);
    const [referenceNoneHide, setReferenceNoneHide] = useState(false);
    const [referenceUrlHide, setReferenceUrlHide] = useState(true);
    const [referenceUploadHide, setReferenceUploadHide] = useState(true);

    const [pdfLinkType, setpdfLinkType] = useState(1);
    const [pdfUrlLinkHide, setPdfUrlLinkHide] = useState(false);
    const [pdfUploadHide, sePdfUploadHide] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    const [isM, setIsM] = useState('');

    const [form] = Form.useForm();
    useEffect(() => {
        if (categoryStatus === 'idle') {
            dispatch(fetchAllCategoryList());
        }
        if (segmentStatus === 'idle') {
            dispatch(fetchSegmentList());
        }
        setIsLoading(false);
        return () => {
        }
    }, [])// eslint-disable-next-line react-hooks/exhaustive-deps

    //validateFields set contentType
    useEffect(() => {
        if (!localTaskHide) {
            resetPdf();
            resetWechatLink();
        }
        if (!pdfHide) {
            resetWechatLink();
            resetLocalTask();
            form.validateFields(['wechatUrl']);
        }
        if (!weChatHide) {
            resetLocalTask();
            resetPdf();
            form.validateFields(['subTitle', 'content']);
        }
    }, [contentType]);// eslint-disable-line

    useEffect(() => {
        if (referenceType === 1) {
            resetURLLink();
            resetUploadPDF();
        }
        if (referenceType === 2) {
            resetUploadPDF();
        }
        if (referenceType === 3) {
            resetURLLink();
        }
    }, [referenceType]);// eslint-disable-line


    useEffect(() => {
        if (pdfLinkType === 1) {
            form.resetFields(['pdfFile', 'pdfFileName']);
            setPDFImage();
            setPDFImageName();
        }
        if (pdfLinkType === 2) {
            form.resetFields(['pdfUrl']);
        }
    }, [pdfLinkType]);// eslint-disable-line


    const resetLocalTask = () => {
        form.resetFields(['contentList']);
        setImageNameArray([]);
        setImageUrlArray([]);
        resetReference();
        form.setFieldsValue({'referenceType': 1});
        setReferenceNoneHide(false);
        setReferenceUrlHide(true);
        setReferenceUploadHide(true);
    }
    const resetWechatLink = () => {
        form.resetFields(['wechatUrl']);
    }
    const resetPdf = () => {
        form.resetFields(['pdfFile', 'pdfFileName', 'pdfUrl']);
        setPDFImage();
        setPDFImageName();
        // form.setFieldsValue({'pdfLinkType':1});
        // setPdfUrlLinkHide(1)
    }
    const resetReference = () => {
        resetURLLink();
        resetUploadPDF();
        form.resetFields(['referenceType']);
    }

    const resetURLLink = () => {
        form.resetFields(['referenceTitle', 'referenceLink']);
    }
    const resetUploadPDF = () => {
        form.resetFields(['referenceTitlePdf', 'referenceFile']);
        setContentPDFImage();
        setContentPDFImageName();
    }
    //change content select show and hidden the type content area
    const onChangeContentSelect = e => {
        if (e.target.value === 1) {
            setLocalTaskHide(false);
            setWeChatHide(true);
            setPdfHide(true);
        }
        if (e.target.value === 2) {
            setLocalTaskHide(true);
            setWeChatHide(false);
            setPdfHide(true);
        }
        if (e.target.value === 3) {
            setLocalTaskHide(true);
            setWeChatHide(true);
            setPdfHide(false);
        }
        // const wechatUrl = form.getFieldValue('wechatUrl');
        // form.resetFields();
        setContentType(e.target.value);
    };

    const onChangepdfSelect = e => {
        if (e.target.value === 1) {
            setPdfUrlLinkHide(false);
            sePdfUploadHide(true);
        }
        if (e.target.value === 2) {
            setPdfUrlLinkHide(true);
            sePdfUploadHide(false);
        }
        setpdfLinkType(e.target.value);
    };

    const onChangeReferenceSelect = e => {
        if (e.target.value === 1) {
            setReferenceNoneHide(false);
            setReferenceUrlHide(true);
            setReferenceUploadHide(true);
        }
        if (e.target.value === 2) {
            setReferenceNoneHide(true);
            setReferenceUrlHide(false);
            setReferenceUploadHide(true);
        }
        if (e.target.value === 3) {
            setReferenceNoneHide(true);
            setReferenceUrlHide(true);
            setReferenceUploadHide(false);
        }
        setReferenceType(e.target.value);
    };
    //get segmentList
    const segmentList = useSelector(selectTask).segmentList;
    const segmentOptions = segmentList.map((segment) => (
        <Option key={segment.key} value={segment.segmentId}> {segment.segmentTitle}</Option>
    ));
    //get categoryList
    const categoryList = useSelector(selectTask).categoryList;
    //set form
    const history = useHistory();
    const [coverImageUrl, setCoverImageUrl] = useState();
    const [coverImageName, setCoverImageName] = useState();
    const [pdfImage, setPDFImage] = useState();
    const [pdfImageName, setPDFImageName] = useState();
    const [contentpdfImage, setContentPDFImage] = useState();
    const [contentpdfImageName, setContentPDFImageName] = useState();

    const [loadingCoverState, setCoverLoadingState] = useState(false);
    const [loadingState, setLoadingState] = useState(false);
    const [loadingPDFState, setLoadingPDFState] = useState(false);
    const [loadingContentPDFState, setLoadingContentPDFState] = useState(false);
    const [imageUrlArray, setImageUrlArray] = useState([]);
    const [imageNameArray, setImageNameArray] = useState([]);
    const contentList = [];

    //###image component -- start//
    function getBase64(img, callback) {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }

    function getBase64Pdf(pdf, callback) {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(pdf);
    }

    function beforeUpload(file) {
        const arry = file.name.split('.');
        const typeImage = arry[arry.length - 1]
        const isJpgOrPng = (file.type === 'image/jpeg' && typeImage === 'jpg') || file.type === 'image/png';
        if (!isJpgOrPng) {
            const config = {
                title: (<><span style={{"fontWeight": "bold"}}
                                className='font-color-error'>File format Error</span></>),
                content: 'File format not supported.',
            }
            showErrorModal(config);
            return Upload.LIST_IGNORE;
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            const config = {
                title: (<><span style={{"fontWeight": "bold"}} className='font-color-error'>File Size Error</span></>),
                content: 'File is too large to upload. Max.2mb.',
            }
            showErrorModal(config);
            return Upload.LIST_IGNORE;
            ;
        }
    }

    function beforeUploadPDF(file) {
        const ispdf = file.type === 'application/pdf';
        if (!ispdf) {
            const config = {
                title: (<><span style={{"fontWeight": "bold"}}
                                className='font-color-error'>File format Error</span></>),
                content: 'File format not supported.',
            }
            showErrorModal(config);
            return Upload.LIST_IGNORE;
        }
        const isLt10M = file.size / 1024 / 1024 < 10;
        if (!isLt10M) {
            const config = {
                title: (<><span style={{"fontWeight": "bold"}} className='font-color-error'>File Size Error</span></>),
                content: 'File is too large to upload. Max.10mb.',
            }
            showErrorModal(config);
            return Upload.LIST_IGNORE;
            ;
        }
    }

    const handleImgChange = (info, param) => {
        if (info.file.status === 'uploading') {
            setLoadingState(true);
            return;
        }
        if (info.file.originFileObj) {
            // if (info.file.status === 'done') {
            // Get this url from response in real world.
            getBase64(info.file.originFileObj, imageUrl => {
                    //set the index image value
                    imageUrlArray[param.index] = imageUrl;
                    imageNameArray[param.index] = info.file.name;
                    setImageNameArray(imageNameArray);
                    setImageUrlArray(imageUrlArray);
                    setLoadingState(false);
                }
            );
            // }
        }
    };
    const handleCoverChange = (info) => {
        if (info.file.status === 'uploading') {
            setCoverLoadingState(true);
            return;
        }
        if (info.file.originFileObj) {
            //if (info.file.status === 'done') {
            // Get this url from response in real world.
            getBase64(info.file.originFileObj, imageUrl => {
                setCoverLoadingState(false);
                setCoverImageUrl(imageUrl);
                setCoverImageName(info.file.name);
            });
            //}
        }
    }

    const handlePDFChange = (info) => {
        if (info.file.status === 'uploading') {
            setLoadingPDFState(true);
            return;
        }
        if (info.file.status === "removed") {
            return;
        }
        if (info.file.originFileObj) {
            getBase64Pdf(info.file.originFileObj, result => {
                info.file.status = 'done';
                setLoadingPDFState(false);
                setPDFImage(result);
                setPDFImageName(info.file.name);
            });

        }
    };

    const handleContentPDFChange = (info) => {
        if (info.file.status === 'uploading') {
            setLoadingContentPDFState(true);
            return;
        }
        if (info.file.status === "removed") {
            return;
        }
        if (info.file.originFileObj) {
            getBase64Pdf(info.file.originFileObj, result => {
                info.file.status = 'done';
                //info.file.status = 'done';
                setLoadingContentPDFState(false);
                setContentPDFImage(result);
                setContentPDFImageName(info.file.name);
            });
        }
    };

    //const { loading, imageUrl } = this.state;
    const uploadButton = (
        <div className='upload-txt-color'>
            {loadingState ? <LoadingOutlined/> : <UploadOutlined/>}
            <div style={{marginTop: 8}}>Upload image here</div>
        </div>
    );
    const uploadCoverButton = (
        <div className='upload-txt-color'>
            {loadingCoverState ? <LoadingOutlined/> : <UploadOutlined/>}
            <div style={{marginTop: 8}}>Upload image here</div>
        </div>
    );
    const uploadPDFButton = (
        <div className='upload-txt-color'>
            {loadingPDFState ? <LoadingOutlined/> : <UploadOutlined/>}
            <div style={{marginTop: 8}}>Upload PDF here</div>
        </div>
    );
    const uploadContentPDFButton = (
        <div className='upload-txt-color'>
            {loadingContentPDFState ? <LoadingOutlined/> : <UploadOutlined/>}
            <div style={{marginTop: 8}}>Upload PDF here</div>
        </div>
    );

    //###image component -- end//
    //reset form
    const checkBusiness = () => {
        setIsM('validating');
        setCanPublish(false);
        debounce(form);
    };

    const [timerId, setTimerId] = useState(null);
    const debounce = (form, delay = 3000) => {
        //期间间隔执行 节流
        if (timerId) clearTimeout(timerId);
        setTimerId(setTimeout(() => {
            const businessId = form.getFieldValue("businessId");
            const isErrorValue = form.getFieldError("businessId")[0] ? true : false;
            if (isErrorValue) return false;
            dispatch(checkBusinessId({businessId: businessId, taskUuid: ''})).then((res) => {
                setIsM('validating');
                if (res.payload.flag && res.payload.flag === 'Y') {
                    const config = {
                        title: (<><span style={{"fontWeight": "bold"}}
                                        className='font-color-error'>Duplicate Error</span></>),
                        icon: (<ExclamationCircleOutlined className='font-color-error'/>),
                        content: 'Duplicate Business Id.  Please modify!'
                    }
                    showErrorModal(config);
                    setIsM('error');
                    return false;
                } else if (res.payload.flag && res.payload.flag === 'N') {
                    setIsM('success');
                    // const config = {
                    //   title: (<><span style={{"fontWeight":"bold"}} className='font-color-success' >Sucess Message</span></>),
                    //   icon:  (<ExclamationCircleOutlined className='font-color-sucess'/>),
                    //   content: ' No Duplicate Business Id!'
                    // }
                    // showSuccessModal(config);
                }
                setCanPublish(true);
            });
        }, delay))
        console.log(timerId);

    }


    function handlChangeSegment(e) {
        //console.log(`selected ${value}`);
    }


    const onChangeCategory = (value) => {
    };
    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };

    const [canPublish, setCanPublish] = useState(true);
    // form submit
    const onFinish = async (values) => {
        setCanPublish(false);
        //check business id only in system
        const businessId = form.getFieldInstance('businessId').input.value;
        let canSubmit = true;
        await dispatch(checkBusinessId({businessId: businessId, taskUuid: ''})).then((res) => {
            if (res.payload.flag && res.payload.flag === 'Y') {
                const config = {
                    title: (<><span style={{"fontWeight": "bold"}}
                                    className='font-color-error'>Error Published saved</span></>),
                    icon: (<ExclamationCircleOutlined className='font-color-error'/>),
                    content: 'Duplicate Business Id.Please modify!'
                }
                showErrorModal(config);
                canSubmit = false;
                setIsLoading(false);
                setCanPublish(true);
                return false;
            }
        });
        if (!canSubmit) return false;
        setIsLoading(true);
        if (form.getFieldsValue().contentList.length > 0 && imageNameArray.length > 0) {
            for (let i = 0; i < form.getFieldsValue().contentList.length; i++) {
                form.getFieldsValue().contentList[i].imgName = imageNameArray[i]
                form.getFieldsValue().contentList[i].imgFile = imageUrlArray[i]
            }
        }
        const status = {status: 1}
        const coverImage = {
            imgFile: coverImageUrl,
            imgName: coverImageName,
        }
        const referencePdfFile = {
            referenceFile: contentpdfImage,
            referenceFileName: contentpdfImageName

        }
        const pdfFile = {
            pdfFile: pdfImage,
            pdfFileName: pdfImageName
        }
        const params = {
            ...form.getFieldsValue(), ...status, ...coverImage, ...referencePdfFile, ...pdfFile
        }

        dispatch(saveTask(params)).then((res) => {
            if (res.payload.code === 0) {
                const config = {
                    title: 'Published successfully',
                    content: (<><p>This task published successfully. You can manage it in <span
                        style={{"fontWeight": "bold"}}>Tasks management</span> panel.</p></>),
                    onOk: (() => history.push('/task'))
                }
                setIsLoading(false);
                showSuccessModal(config);
                dispatch(changeTaskListyStatus())
                setCanPublish(true);
            } else {
                const config = {
                    title: 'Error Published saved',
                    content: 'This task saved as published failed.'
                }
                setIsLoading(false);
                showErrorModal(config);
                setCanPublish(true);
            }
        });
    };
    const onFinishFailed = values => {
        const config = {
            title: 'Fail to save as published',
            content: 'There are some errors on your task edits, please correct them before publish.'
        }
        showErrorModal(config);

    };

    const handleSubmit = async () => {
        //setIsLoading(true);
        try {
            form.submit();
            // const values = await form.validateFields();
            // console.log('Success:', values);
        } catch (errorInfo) {
            console.log('Failed:', errorInfo);
        }
    }
    const handleSubmitDraft = async () => {
        try {
            if (form.getFieldsValue().contentList.length > 0 && imageNameArray.length > 0) {
                for (let i = 0; i < form.getFieldsValue().contentList.length; i++) {
                    form.getFieldsValue().contentList[i].imgName = imageNameArray[i]
                    form.getFieldsValue().contentList[i].imgFile = imageUrlArray[i]
                }
            }
            const status = {status: 3}
            const coverImage = {
                imgFile: coverImageUrl,
                imgName: coverImageName,
            }
            const referencePdfFile = {
                referenceFile: contentpdfImage,
                referenceFileName: contentpdfImageName

            }
            const pdfFile = {
                pdfFile: pdfImage,
                pdfFileName: pdfImageName
            }
            const params = {
                ...form.getFieldsValue(), ...status, ...coverImage, ...referencePdfFile, ...pdfFile
            }

            setIsLoading(true);
            // const param = { status: 3 , taskTitle: taskTitle};
            dispatch(saveTask(params)).then((res) => {
                if (res.payload.code === 0) {
                    const config = {
                        title: 'Draft saved',
                        content: (<><p>This task saved as draft. You can manage it in <span
                            style={{"fontWeight": "bold"}}>Tasks management</span> panel.</p></>),
                        onOk: (() => {
                            history.push('/task')
                        })
                    }

                    setIsLoading(false);
                    showSuccessModal(config);
                    dispatch(changeTaskListyStatus())
                } else {
                    const config = {
                        title: 'Error Draft saved',
                        content: 'This task saved as draft failed.'
                    }
                    showErrorModal(config);

                    setIsLoading(false);
                }
            });
            //console.log('Success:', values);
        } catch (errorInfo) {
            console.log('Failed:', errorInfo);
        }
    }
    const handleSaveDraft = () => {
        const businessId = form.getFieldValue(['businessId']);
        if (businessId) {
            if (isM === 'error') {
                form.scrollToField('businessId');
                const config = {
                    title: 'Fail to save as draft',
                    content: 'Business Id is required, please input to proceed.'
                }
                showErrorModal(config);
                return false;
            }
        } else {
            form.validateFields(['businessId']);
            form.scrollToField('businessId');
            const config = {
                title: 'Fail to save as draft',
                content: 'Business Id is required, please input to proceed.'
            }
            showErrorModal(config);
            return false;
        }

        const taskTitle = form.getFieldValue(['taskTitle']);
        const taskTitleError = form.getFieldError('taskTitle');
        if (taskTitle) {
            if (taskTitleError.length === 0) {
                handleSubmitDraft();
            } else {
                form.validateFields(['taskTitle']);
                form.scrollToField('taskTitle');
                const config = {
                    title: 'Fail to save as draft',
                    content: 'Task title length less than 150 characters.'
                }
                showErrorModal(config);
            }
        } else {
            form.validateFields(['taskTitle']);
            form.scrollToField('taskTitle');
            const config = {
                title: 'Fail to save as draft',
                content: 'Task title is required, please input to proceed.'
            }
            showErrorModal(config);
        }
    }

    const handleCancelBack = () => {
        // if(!isEditForm){
        //   history.push('/task')
        //   return false;
        // }
        confirm({
            title: (<><span style={{"fontWeight": "bold"}}
                            className='font-color-error'>You have unsaved changes</span></>),
            icon: <ExclamationCircleOutlined className='font-color-error'/>,
            content: 'You have edits on this task, which action you want to proceed?',
            okText: 'Discard and back',
            cancelText: 'Save as draft',
            okType: 'danger',
            onOk() {

                history.push('/task')
            },
            onCancel() {
                const businessId = form.getFieldValue(['businessId']);
                if (businessId) {
                    if (isM === 'error') {
                        form.scrollToField('businessId');
                        const config = {
                            title: 'Fail to save as draft',
                            content: 'Business Id is required, please input to proceed.'
                        }
                        showErrorModal(config);
                        return false;
                    }
                } else {
                    form.validateFields(['businessId']);
                    form.scrollToField('businessId');
                    const config = {
                        title: 'Fail to save as draft',
                        content: 'Business Id is required, please input to proceed.'
                    }
                    showErrorModal(config);
                    return false;
                }
                const taskTitle = form.getFieldValue(['taskTitle']);
                const taskTitleError = form.getFieldError('taskTitle');
                if (taskTitle) {
                    if (taskTitleError.length === 0) {
                        handleSubmitDraft();
                    } else {
                        form.validateFields(['taskTitle']);
                        form.scrollToField('taskTitle');
                        const config = {
                            title: 'Fail to save as draft',
                            content: 'Task title length less than 150 characters.'
                        }
                        showErrorModal(config);
                    }
                } else {
                    form.validateFields(['taskTitle']);
                    form.scrollToField('taskTitle');
                    const config = {
                        title: 'Saved as draft failed',
                        content: 'Task title is required, please input to proceed.'
                    }
                    showErrorModal(config);
                }
            },
        });
    }

    const [taskTitleMsg, setTaskTtileMsg] = useState();
    const [taskTitleStatus, setTaskTitleStatus] = useState('');
    const handleTaskTitle = (filed) => {
        setTaskTtileMsg('');
        setTaskTitleStatus('');
        const inputValue = filed.target.value;
        if (inputValue.length === 0) {
            setTaskTitleStatus('error');
            setTaskTtileMsg('Please complete mandatory field.');
        }
        if (inputValue.length > 30) {
            setTaskTitleStatus('warning');
            setTaskTtileMsg('The title is too long to fully displayed.');
        }
        if (inputValue.length > 150) {
            setTaskTitleStatus('error');
            setTaskTtileMsg('Title length less than 150 characters.');
        }
    }
    const onFormChange = (allFields) => {
        //isEditForm = true;
    }

    function imageHandler(obj) {
        const input = document.createElement('input')
        input.setAttribute('type', 'file')
        input.setAttribute('accept', 'image/*')
        input.setAttribute('multiple', 'multiple')
        input.click()
        input.onchange = async () => {
            let quill = reactQuillRef.current[currentIndex.current].editor//获取到编辑器本身
            const cursorPosition = quill.getSelection().index;//获取当前光标位置
            let params = {}
            const user = JSON.parse(localStorage.getItem('access-user'))

            getBase64(input.files[0], imageUrl => {
                //set the index image value
                params = {
                    file: imageUrl,
                    fileName: input.files[0].name,
                    account: user.account
                }
                dispatch(uploadRichImg(params)).then((res) => {
                    if (res.payload) {
                        const link = res.payload.fileUrl
                        quill.insertEmbed(cursorPosition, "image", link);//插入图片
                        quill.setSelection(cursorPosition + 1);//光标位置加1
                    } else {

                    }
                });
                const link = ''
                quill.insertEmbed(cursorPosition, "image", link);//插入图片
                quill.setSelection(cursorPosition + 1);//光标位置加1
            })

        }

    }

    var Size = ReactQuill.Quill.import('attributors/style/size');
    Size.whitelist = ['10px', '12px', '14px', '16px', '18px', '20px', '24px', '26px', '32px', '48px'];
    ReactQuill.Quill.register(Size, true);
    const quillOption = useMemo(() => ({
        toolbar: {
            container: [
                [{'size': ['10px', '12px', '14px', '16px', '18px', '20px', '24px', '26px', '32px', '48px']}],
                ['bold', 'italic', 'underline', 'strike'],
                ['blockquote'],
                [{list: 'ordered'}, {list: 'bullet'}],
                [{align: []}],
                [{color: []}, {background: []}],
                ['link', 'image'],
                ['clean']
            ],
            theme: 'snow',
            handlers: {
                image: imageHandler
            }
        },

    }), [])

    const handleChange = (index) => {
        currentIndex.current = index;
    }

    return (
        <>

            <Layout>
                <SideMenu
                    index='3'
                >
                </SideMenu>
                <Content style={{padding: '1.5rem'}}>
                    <Spin
                        wrapperClassName="self-spin-wrapper"
                        size="large"
                        spinning={isLoading}
                    >
                        <Form
                            scrollToFirstError
                            labelCol={{span: 8}} wrapperCol={{span: 14}}
                            form={form}
                            name="task_form"
                            layout="vertical"
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            autoComplete="off"
                            onFieldsChange={(_, allFields) => {
                                onFormChange(allFields);
                            }}
                            initialValues={{
                                'contentType': contentType,
                                'pdfLinkType': pdfLinkType,
                                'referenceType': referenceType
                            }}
                        >
                            <div className="site-layout-background content-div createTask">
                                <Content style={{margin: '1.5rem 1.5rem 0'}}>
                                    <Content style={{margin: '.6rem 0'}}>
                                        <div className="flexBetweenBar">
                                            <Title level={2}>Create new task</Title>
                                            {
                                                canPublish ?
                                                    <Button type="default" size="large" onClick={handleCancelBack}>Cancel
                                                        and back</Button>
                                                    : <Button type="default" size="large" disabled
                                                              onClick={handleCancelBack}>Cancel and back</Button>
                                            }
                                        </div>
                                    </Content>
                                    <Divider/>
                                    <Content style={{margin: '1rem 1rem 0 0'}}>
                                        <div className="note_tip"><ExclamationCircleOutlined/> Note: Please input all
                                            the mandatory fileds with asterisk (*)
                                        </div>
                                        <Form.Item className="title-bold-lable"
                                                   label="Business Id"
                                                   name="businessId"
                                                   extra="BusinessId length less than 10 characters."
                                                   hasFeedback validateStatus={isM}
                                                   rules={
                                                       [
                                                           {
                                                               required: true,
                                                               message: 'Please complete mandatory field'
                                                           },
                                                           {
                                                               pattern: new RegExp(/^.{1,10}$/),
                                                               message: 'Length less than 10 characters.'

                                                           }
                                                       ]
                                                   }
                                        >
                                            <Input className="input_bottom_only" size="large"
                                                   placeholder="Input Business Id here" onChange={checkBusiness}/>
                                        </Form.Item>
                                        <Form.Item className="title-bold-lable"
                                                   validateStatus={taskTitleStatus}
                                                   label="Task title"
                                                   name="taskTitle"
                                                   required
                                                   extra="Recommended title length less than 30 characters"
                                                   help={taskTitleMsg}
                                                   rules={
                                                       [
                                                           {
                                                               required: true,
                                                               message: 'Please complete mandatory field',

                                                           },
                                                           {
                                                               validator: (_, value) => {
                                                                   if (value && value.length > 150) {
                                                                       return Promise.reject('Title length less than 150 characters.')
                                                                   } else {
                                                                       return Promise.resolve()
                                                                   }
                                                               }

                                                           },
                                                       ]
                                                   }
                                        >
                                            <Input className="input_bottom_only" size="large"
                                                   placeholder="Input title here" onChange={handleTaskTitle}/>
                                        </Form.Item>
                                        <Form.Item className="title-bold-lable" label="Task reading time (.mins)"
                                                   name="taskDuration"
                                                   rules={
                                                       [
                                                           {
                                                               required: true,
                                                               message: 'Please complete mandatory field'
                                                           }
                                                       ]
                                                   }
                                        >
                                            <InputNumber size="large" style={{width: 240}} min={1} max={999}
                                                         placeholder="Input number here, eg.3"
                                            />
                                        </Form.Item>
                                        <Form.Item className="title-bold-lable" label="Main cover image"

                                            // 以下两条是必须的
                                                   valuePropName="fileList"
                                            // 如果没有下面这一句会报错
                                                   getValueFromEvent={normFile}
                                                   extra="Supported file format: 5:4 ratio, JPG and PNG, Min. res. 750(W) by 600(H), Max. 2mb"
                                                   name="coverImageUrl"
                                                   rules={
                                                       [
                                                           {
                                                               required: true,
                                                               message: 'Please complete mandatory field'
                                                           }
                                                       ]
                                                   }>

                                            <Upload
                                                showUploadList={false}
                                                className="avatar-uploader"
                                                //listType="picture-card"
                                                listType="picture"
                                                action=""
                                                beforeUpload={beforeUpload}
                                                onChange={handleCoverChange}
                                                accept="image/png,.jpg"
                                            >
                                                {coverImageUrl ? <img src={coverImageUrl} alt="cover img"
                                                                      style={{maxWidth: '450px'}}/> : uploadCoverButton}
                                            </Upload>
                                        </Form.Item>
                                        <Form.Item className="title-bold-lable" label="Task user segment"
                                                   name="segmentId">
                                            <Select size='large' style={{width: 400}} placeholder="Select user segment"
                                                    onChange={handlChangeSegment} allowClear>
                                                {segmentOptions}
                                            </Select>
                                        </Form.Item>
                                        <Form.Item className="title-bold-lable" label="Task category" name="category"
                                                   rules={
                                                       [
                                                           {
                                                               required: true,
                                                               message: 'Please complete mandatory field.'
                                                           }
                                                       ]
                                                   }
                                        >
                                            <Cascader size='large' style={{width: 400}} options={categoryList}
                                                      onChange={onChangeCategory} placeholder="Select category"/>
                                        </Form.Item>
                                        <Form.Item className="title-bold-lable"
                                                   label="Task author"
                                                   name="taskAuthor"
                                                   rules={[{max: 50, message: 'Task author less than 50 characters.'}]}
                                        >
                                            <Input className="input_bottom_only" size="large"
                                                   placeholder="Input author name"/>
                                        </Form.Item>
                                        <Form.Item className="title-bold-lable" label="Task Content" name="contentType">
                                            <Radio.Group onChange={onChangeContentSelect}>
                                                <Radio value={1}>Local task</Radio>
                                                <Radio value={2}>External Link</Radio>
                                                <Radio value={3}>PDF</Radio>
                                            </Radio.Group>
                                        </Form.Item>
                                        <div className={localTaskHide ? 'hidden' : ''}>
                                            <div className="localTask">
                                                <Form.List name="contentList"
                                                           initialValue={[{subTitle: "", imgFile: '', content: ''}]}>
                                                    {(fields, {add, remove}) => (
                                                        <>
                                                            {fields.map((field, index) => (
                                                                <div key={field.key}>
                                                                    <div><Title className="font-color-sec" level={4}>Content
                                                                        section - {index + 1}</Title></div>
                                                                    <Space align="baseline" direction="vertical "
                                                                           style={{width: "100%"}}>
                                                                        <div className="section_left_border"></div>
                                                                        <Space align="baseline">
                                                                            <Form.Item
                                                                                {...field}
                                                                                className="title-bold-lable"
                                                                                label="Section sub-title"
                                                                                name={[field.name, 'subTitle']}
                                                                                fieldKey={[field.fieldKey, 'subTitle']}
                                                                                rules={[{
                                                                                    required: !localTaskHide,
                                                                                    message: 'Please complete mandatory field.'
                                                                                }, {
                                                                                    max: 150,
                                                                                    message: 'SubTitle less than 150 characters.'
                                                                                }]}
                                                                                style={{width: 650}}
                                                                            >
                                                                                <Input
                                                                                    className="input_bottom_only gray_bg"
                                                                                    placeholder="Input sub-title here"/>
                                                                            </Form.Item>
                                                                            {fields.length > 1 ? (
                                                                                <MinusCircleOutlined
                                                                                    className="removeSection"
                                                                                    onClick={() => {
                                                                                        // TODO: fix 嵌套 的 form.list 调用remove方法，数据正确更新了 视图未能正确更新，
                                                                                        remove(field.name);
                                                                                        // removeImage(index);
                                                                                        imageUrlArray.splice(index, 1);
                                                                                        message.success('Remove the Content section-' + index);
                                                                                        // 下面注释的代码能触发视图重新更新，but 感觉挺浪费性能的
                                                                                        // const _structs = form.getFieldValue('structs');
                                                                                        // form.resetFields();
                                                                                        // form.setFieldsValue({
                                                                                        //   structs: _structs,
                                                                                        // });
                                                                                    }}/>
                                                                            ) : null}
                                                                        </Space>
                                                                        <Form.Item
                                                                            {...field}
                                                                            label="Section image"
                                                                            className="title-bold-lable"
                                                                            name={[field.name, 'imgFile']}
                                                                            fieldKey={[field.fieldKey, 'imgFile']}
                                                                            extra="Supported file format: JPG and PNG, Max. 2mb"
                                                                            style={{width: 850}}
                                                                        >

                                                                            <Upload
                                                                                //listType="picture-card"
                                                                                listType="picture"
                                                                                className="avatar-uploader"
                                                                                showUploadList={false}
                                                                                action=""
                                                                                beforeUpload={beforeUpload}
                                                                                onChange={(info) => {
                                                                                    handleImgChange(info, {index})
                                                                                }}
                                                                                accept="image/png,.jpg"

                                                                            >
                                                                                {imageUrlArray[index] ?
                                                                                    <img src={imageUrlArray[index]}
                                                                                         alt="image"
                                                                                         style={{width: '100%'}}/> : uploadButton}
                                                                            </Upload>
                                                                        </Form.Item>
                                                                        <Form.Item
                                                                            {...field}
                                                                            label="Section paragraph"
                                                                            className="title-bold-lable"
                                                                            name={[field.name, 'content']}
                                                                            fieldKey={[field.fieldKey, 'content']}
                                                                            rules={[{
                                                                                required: !localTaskHide,
                                                                                message: 'Please complete mandatory field.'
                                                                            }]}
                                                                            onClick={() => handleChange(index)}
                                                                            style={{width: 850}}
                                                                        >

                                                                            <ReactQuill
                                                                                value={field}
                                                                                ref={el => reactQuillRef.current[index] = el}
                                                                                onChange={handleChange(index)}
                                                                                modules={quillOption}/>
                                                                            {/* <TextArea showCount maxLength={1000} style={{ height: '50px' }} placeholder="Input paragraph content here"/> */}
                                                                        </Form.Item>
                                                                    </Space>
                                                                </div>
                                                            ))}

                                                            <Form.Item>
                                                                <Button type="primary" size="large"
                                                                        icon={<PlusOutlined/>} onClick={() => {
                                                                    add()
                                                                }}>
                                                                    Add another section
                                                                </Button>
                                                            </Form.Item>
                                                        </>
                                                    )}
                                                </Form.List>
                                                <Form.Item className="title-bold-lable" label="Task reference type"
                                                           name="referenceType">
                                                    <Radio.Group onChange={onChangeReferenceSelect}>
                                                        <Radio value={1}>None</Radio>
                                                        <Radio value={2}>URL link</Radio>
                                                        <Radio value={3}>Upload PDF</Radio>
                                                    </Radio.Group>
                                                </Form.Item>
                                                <Form.Item className={referenceUrlHide ? 'hidden' : ''}>
                                                    <Form.Item className="title-bold-lable"
                                                               label="Task reference title"
                                                               name="referenceTitle"
                                                               rules={
                                                                   [
                                                                       {
                                                                           required: !referenceUrlHide && !localTaskHide,
                                                                           message: 'Please complete mandatory field'
                                                                       },
                                                                       {
                                                                           pattern: new RegExp(/^.{1,150}$/),
                                                                           message: 'Length less than 150 characters.'

                                                                       }
                                                                   ]
                                                               }
                                                    >
                                                        <Input className="input_bottom_only gray_bg" size="large"
                                                               placeholder="Input displayed title here"/>
                                                    </Form.Item>
                                                    <Form.Item className="title-bold-lable"
                                                               label="Task reference URL"
                                                               name="referenceLink"
                                                               rules={
                                                                   [
                                                                       {
                                                                           required: !referenceUrlHide && !localTaskHide,
                                                                           message: 'Please complete mandatory field',
                                                                       },
                                                                       {
                                                                           pattern: new RegExp(/(http|https):\/\/\S*/),
                                                                           message: 'Please input valid URL address'
                                                                       },
                                                                       {
                                                                           whitespace: true
                                                                       }

                                                                   ]
                                                               }
                                                    >
                                                        <Input className="input_bottom_only gray_bg" size="large"
                                                               placeholder="Http://"/>
                                                    </Form.Item>
                                                </Form.Item>
                                                <Form.Item className={referenceUploadHide ? 'hidden' : ''}>
                                                    <Form.Item className="title-bold-lable"
                                                               label="Task reference title"
                                                               name="referenceTitlePdf"
                                                               rules={
                                                                   [
                                                                       {
                                                                           required: !referenceUploadHide && !localTaskHide,
                                                                           message: 'Please complete mandatory field'
                                                                       },
                                                                       {
                                                                           pattern: new RegExp(/^.{1,150}$/),
                                                                           message: 'Length less than 150 characters.'

                                                                       }
                                                                   ]
                                                               }

                                                    >
                                                        <Input className="input_bottom_only gray_bg" size="large"
                                                               placeholder="Input displayed title here"/>
                                                    </Form.Item>
                                                    <Form.Item
                                                        className="title-bold-lable"
                                                        label="PDF file upload"
                                                        // 以下两条是必须的
                                                        valuePropName="fileList"
                                                        // 如果没有下面这一句会报错
                                                        getValueFromEvent={normFile}
                                                        extra="Supported file format: PDF, Max. 10mb"
                                                        name="referenceFile"
                                                        rules={
                                                            [
                                                                {
                                                                    required: !referenceUploadHide && !localTaskHide,
                                                                    message: 'Please complete mandatory field.'
                                                                }
                                                            ]
                                                        }

                                                    >
                                                        <Upload
                                                            action=""
                                                            listType="picture"
                                                            maxCount={1}
                                                            beforeUpload={beforeUploadPDF}
                                                            onChange={handleContentPDFChange}
                                                            accept="application/pdf,.pdf"
                                                        >
                                                            {uploadContentPDFButton}
                                                        </Upload>
                                                    </Form.Item>
                                                </Form.Item>

                                                {/* <Form.Item>
                              <Button type="primary" htmlType="submit">
                                Submit
                              </Button>
                            </Form.Item> */}
                                            </div>
                                        </div>
                                        <div className={weChatHide ? 'hidden' : ''}>
                                            <Form.Item className="title-bold-lable"
                                                       label="Task URL"
                                                       name="wechatUrl"
                                                       rules={
                                                           [
                                                               {
                                                                   required: !weChatHide,
                                                                   message: 'Please complete mandatory field'
                                                               },
                                                               {
                                                                   pattern: new RegExp(/(http|https):\/\/\S*/),
                                                                   message: 'Please input valid URL address.'
                                                               }
                                                           ]
                                                       }
                                            >
                                                <Input className="input_bottom_only" placeholder="Http://"/>
                                            </Form.Item>
                                        </div>
                                        <div className={pdfHide ? 'hidden' : ''}>
                                            <Form.Item className="title-bold-lable" label="PDF type" name="pdfLinkType">
                                                <Radio.Group onChange={onChangepdfSelect}>
                                                    <Radio value={1}>URL link</Radio>
                                                    <Radio value={2}>Upload file</Radio>
                                                </Radio.Group>
                                            </Form.Item>
                                            <div className={pdfUrlLinkHide ? 'hidden' : ''}>
                                                <Form.Item className="title-bold-lable"
                                                           label="PDF URL"
                                                           name="pdfUrl"
                                                           rules={
                                                               [
                                                                   {
                                                                       required: !pdfHide && !pdfUrlLinkHide,
                                                                       message: 'Please complete mandatory field.'
                                                                   },
                                                                   {
                                                                       pattern: new RegExp(/(http|https):\/\/\S*/),
                                                                       message: 'Please input valid URL address.'
                                                                   }
                                                               ]
                                                           }>
                                                    <Input className="input_bottom_only" placeholder="Http://"/>
                                                </Form.Item>
                                            </div>
                                            <div className={pdfUploadHide ? 'hidden' : ''}>
                                                <Form.Item
                                                    className="title-bold-lable" label="PDF file upload"
                                                    // 以下两条是必须的
                                                    valuePropName="fileList"
                                                    // 如果没有下面这一句会报错
                                                    getValueFromEvent={normFile}
                                                    extra="Supported file format: PDF, Max. 10mb"
                                                    name="pdfFile"
                                                    rules={
                                                        [
                                                            {
                                                                required: !pdfHide && !pdfUploadHide,
                                                                message: 'Please complete mandatory field.'
                                                            }
                                                        ]
                                                    }>
                                                    {/* <Upload
                                    showUploadList={false}          
                                    className="avatar-uploader"
                                    listType="picture-card"
                                    action=""
                                    beforeUpload={beforeUpload}
                                    onChange={handlePDFChange}
                                  >
                                    {pdfImage ? <img src={pdfImage} alt="cover img" style={{ width: '100%' }} /> : uploadPDFButton}
                                  </Upload> */}
                                                    <Upload
                                                        action=""
                                                        listType="picture"
                                                        maxCount={1}
                                                        beforeUpload={beforeUploadPDF}
                                                        onChange={handlePDFChange}
                                                    >
                                                        {uploadPDFButton}
                                                    </Upload>
                                                </Form.Item>
                                            </div>
                                        </div>
                                    </Content>
                                    <Divider/>
                                    <Content style={{margin: '.6rem 0', padding: '0 0 1.8rem 0'}}>
                                        <div className="flexBetweenBar">
                                            <div className="flexBetweenBar">
                                                {
                                                    canPublish ?
                                                        <Button type="primary" size="large" onClick={handleSubmit}>Proceed
                                                            to publish</Button>
                                                        : <Button type="primary" size="large" onClick={handleSubmit}
                                                                  disabled>Proceed to publish</Button>
                                                }
                                                {
                                                    canPublish ?
                                                        <Button style={{margin: '0 0 0 1rem'}} className="draft_btn"
                                                                size="large" onClick={handleSaveDraft}>Save as
                                                            draft</Button>
                                                        : <Button style={{margin: '0 0 0 1rem'}} className="draft_btn"
                                                                  size="large" disabled onClick={handleSaveDraft}>Save
                                                            as draft</Button>
                                                }


                                            </div>
                                            {
                                                canPublish ?
                                                    <Button type="default" size="large" onClick={handleCancelBack}>Cancel
                                                        and back</Button>
                                                    : <Button type="default" size="large" disabled
                                                              onClick={handleCancelBack}>Cancel and back</Button>
                                            }


                                        </div>
                                    </Content>
                                </Content>
                            </div>
                        </Form>

                    </Spin>
                </Content>
            </Layout>
        </>

    )
}