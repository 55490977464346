import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { post } from '../../api/axios'
import { getEndpoint } from '../../api/config'

const initialState = {
  login: [
    ],
  status: 'idle',
  error: null
}


export const fetchLogin = createAsyncThunk('login/fetchLogin', async (params) => {
  const response = await post(getEndpoint('getLogin') , params);
  if(parseInt(response.data.code) === 0){
    const  user = response.data.data;
    localStorage.setItem('access-user', JSON.stringify(user)); // 将用户信息存到localStorage中
  }
  return response.data;
})

export const fetchToken = createAsyncThunk('login/freshToken', async () => {
  const response = await post(getEndpoint('freshToken'));
    let user = JSON.parse(window.localStorage.getItem('access-user'));
    const account = {"token": response.data.data.token}
    const newUser = {
      ...user,...account
    }
    localStorage.setItem('access-user', JSON.stringify(newUser)); // 将用户信息存到localStorage中
  return response.data
})


export const fetchPwdConfirm = createAsyncThunk('login/pwdConfirm', async (params) => {
  let user = JSON.parse(window.localStorage.getItem('access-user'));  
  const newParams = {
    "account": user.account,
	  "newPwd": params.password
  }
  const response = await post(getEndpoint('fetchPwdConfirm') , newParams);
  return response.data;
})




const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    fetchLogin: {
      reducer(state, action) {
        state.login.push(action.payload)
      }
    },
  },
  extraReducers: {
    [fetchLogin.pending]: (state, action) => {
      state.status = 'loading'
    },
    [fetchLogin.fulfilled]: (state, action) => {
      state.status = 'succeeded'
    
    },
    [fetchLogin.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.error.message
    },


    [fetchPwdConfirm.fulfilled]: (state, action) => {
      state.status = 'succeeded'
    },
    [fetchPwdConfirm.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.error.message
    }
  }
})
export const { toLogin} = loginSlice.actions

export default loginSlice.reducer
