import React from 'react'
import { Button } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import '../login.scss';
export const Logout = () => {
    const returnLogin = () => {
      window.location.href = '/login';
    }
    return(
        <div className="login login_bg confirm">
            <Content>
            <div style={{height:443}} className="login_wrapper">
                  <Content>
                    <div className="logo_title">
                      <div className="title"><div className="logo"></div></div>
                      <div className="subtitle">CMS for WeChat mini program</div>
                      <div style={{fontSize:18,textAlign:'center',fontWeight:'bold',height:160,width:'100%',lineHeight:8}}className='font-color-success'>You have successfully logged out</div>
                      <div style={{textAlign:'center'}}><Button type="primary" style={{width:184}}   size="large" onClick={returnLogin}>OK</Button></div>
                    </div>
                  </Content>
            </div>
            </Content>
        </div>
        
    )
}