

import {post} from 'axios';
import { getEndpoint } from './../api/config'
//flag判断是否为登录页
let timeType = false

const testTime = async()=>{
    //设置超时时间 60min
    let timeOut =  60 * 60 ;
    //当前时间
    let currentTime = parseInt(new Date().getTime()/1000); 
    //获取当前路径   是否在登录页
    let arry = window.location.href.split('/');
    let endWith = arry[arry.length-1];
    timeType = endWith ==='login'? true : false;
    //console.log("是否是登录页面",timeType);
    if (!timeType) {
        let user = JSON.parse(localStorage.getItem('access-user'));
        //设置最后的登录时间
        let lastLoginTime = user && user.time ? user.time : '';
        
        // console.log(currentTime - lastLoginTime,timeOut-10);
        //到达规定时间前10秒 进行请求 获取新token 缓冲10秒
        if (currentTime - lastLoginTime === timeOut -10 || currentTime - lastLoginTime === timeOut -9 || currentTime - lastLoginTime === timeOut -8) {
            //再获取一次token
            const response = await post(getEndpoint('freshToken'),{tokens:user.token});
            const newTokenRes = response.data.data;
            let  newUer = {
                account : user.account,                
                firstFlag : user.firstFlag,          
                time : newTokenRes.time,
                token : newTokenRes.token

            }
            localStorage.setItem('access-user', JSON.stringify(newUer)); // 将用户信息存到localStorage中
            //console.log('token时间更新######',lastLoginTime);
            lastLoginTime = newTokenRes.time;//token时间更新
        }
       
        //判断最后一次登录时间与当前时间是否超过60分钟 超过则重新登录
        //当大于60分钟，证明之前一直超时,没有一直操作 给5秒缓冲时间
        if (currentTime - lastLoginTime > timeOut + 5) {
            // 超时操作
            console.log('token超时 时间过长，请重新登录!');
            window.location.href = '/login';
            localStorage.removeItem("access-user");
            sessionStorage.clear();
        }
    }
}

const checkTimeout =  () => {
     window.setInterval(testTime, 1000); 
}
export default checkTimeout;