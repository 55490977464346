import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Table, Layout, Spin, Divider, Typography, Space, Popconfirm} from 'antd';

import { EyeInvisibleOutlined, EyeOutlined, FormOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { showErrorModal } from '../../components/Modal'
import { useHistory } from 'react-router-dom';
import {SideMenu} from '../../components/Menus';
import { fetchTaskList, modifyTaskStatus , selectTask, deleteTask, changeTaskListyStatus, getTask } from './taskSlice'

import './task.scss';
 
export const Task = () => {
    const { Content } = Layout;
    const { Title, Text } = Typography;
    const Publish_Status = 1;
    const Hidden_Status = 2;
    const columns = [
      // {
      //   title: ' #',
      //   width:65,
      //   render: (text, row, index) => {
      //     return {
      //       children: <><span>{++index}</span></>,
      //     };
      //   }
      // },
      {
        title:  <><span>#</span><span style={{margin : '0 0 0 15px'}}>Task Title</span></>,
        dataIndex: 'taskTitle',
        key: 'taskTitle',
        render: (text, row, index) => {
          return {
            children: <><div style={{display:'flex'}}><span >{++index}</span><span style={{margin : '0 0 0 15px', wordBreak :'break-word'}}>{text}</span></div></>,
          };
        }
      },
      {
        title: 'Business Id',
        dataIndex: 'businessId',
        key: 'businessId',
        width:120,
        render: (text, row, index) => {
          return {
            children: <><span style={{}}>{text}</span></>,
          };
        }
      },
      {
        title: 'Updated',
        dataIndex: 'modifyDate',
        key: 'modifyDate',
        width:120,
      },
      {
        title: 'User Segment',
        dataIndex: 'segment',
        key: 'segment',
        width:160,
      },
      {
        title: 'Content Category',
        dataIndex: 'category',
        key: 'category',
      },
      {
        title: 'Status',
        key: 'status',
        dataIndex: 'status',
        width:105,
        render: status => {
          if(status === 1){
            return (
              <span className="status_publish">Published</span>
            );
          }else if(status === 2){
            return (
              <span className="status_hidden">Hidden</span>
            );
          }else if(status === 3){
            return (
              <span className="status_draft">Draft</span>
            );
          }else{
            return (
              <span className="status_draft"> Status Error</span>
            );
          }
        },
      },
      {
        title: 'Actions',
        key: 'action',
        width:150,
        render: (text, record) => {
          if(record.status === 1){
            return(
              <Space size="middle">
                <a className="status_draft" onClick={()=> handleEdit(record)}><FormOutlined/></a>
                <Popconfirm title="Sure change to hidden?" onConfirm={() => { changeStatus(record,Hidden_Status) }}>
                 <a ><EyeInvisibleOutlined/></a>
                </Popconfirm>
                <Popconfirm title="Sure to delete? You cannot undo this action." onConfirm={() => { handleDelete(record) }}>
                  <a><DeleteOutlined /></a>
                </Popconfirm>
              </Space>
             );
          }else if(record.status === 2){
            return(
              <Space size="middle">
                <a  className="status_draft" onClick={()=> handleEdit(record)}><FormOutlined/></a>
                <Popconfirm title="Sure change to publish?" onConfirm={() => { changeStatus(record,Publish_Status) }}>
                  <a ><EyeOutlined /></a>
                </Popconfirm>
                <Popconfirm title="Sure to delete? You cannot undo this action." onConfirm={() => { handleDelete(record) }}>
                  <a ><DeleteOutlined /></a>
                </Popconfirm>
              </Space>
             );
          }else if(record.status === 3){
            return(
              <Space size="middle">
                <a className="status_draft" onClick={()=> handleEdit(record)}><FormOutlined/></a>
                <a disabled><EyeInvisibleOutlined/></a>
                <Popconfirm title="Sure to delete? You cannot undo this action." onConfirm={() => { handleDelete(record) }}>
                  <a ><DeleteOutlined /></a>
                </Popconfirm>
              </Space>
             );
          }
         
        }
      },
    ];
    const taskListyStatus = useSelector(state => state.tasks.status);
    const data = useSelector(selectTask).taskList;
    const dispatch = useDispatch();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState();
    // const [data, setData] = useState([]);
    const [count, setCount] = useState(0);
    useEffect(() => {
      if (taskListyStatus === 'idle') {
        setIsLoading(true);
        dispatch(fetchTaskList()).then(res => { setIsLoading(false) });
       
      }
      return() => {
        dispatch(changeTaskListyStatus())
      }
    },[])

    useEffect(() => {
      setCount(()=>{return  data.length})
    })
    const handleDelete = async(row) => {
        setIsLoading(true);
        await dispatch(deleteTask(row)).then( (res) =>{
          if(parseInt(res.payload.data.code) === 0){
            // const config = {
            //   title:'Success task',
            //   content: 'Delete task Sucess.'
            // }
            // showSuccessModal(config);
            console.log('task success');
          }else{
            const config = {
              title:'Error task',
              content: 'Delete task Unsuccess.'
            }
            showErrorModal(config);
          }
        });
        setIsLoading(false);
        //const dataSource = [...data];
        // data.filter((item) => item.key !== key)
        //setData(dataSource.filter((item) => item.key !== key));
        
    };
    const changeStatus = async(row,status) => {
      const params = {
        row: row,
        status: status
      }
      setIsLoading(true);
      await dispatch(modifyTaskStatus(params));
      setIsLoading(false);
    };
    const handleEdit = async(row) => {
      setIsLoading(true);
      await dispatch(getTask({ taskUuid : row.taskUuid }));
      setIsLoading(false);
      history.push('/task/'+ row.taskUuid);
    }
    return(
        <>
          
            <Layout>
            <SideMenu
              index='3'
            >
            </SideMenu>
            <Layout>
              <Spin
              wrapperClassName="self-spin-wrapper"
              size="large"
              spinning={isLoading}
              > 
                <Content style={{  padding: '1.5rem' }}>         
                <div className="site-layout-background content-div task">
                    <Content style={{ margin: '1.5rem 1rem 0' }}>  
                      <Content style={{ margin: '.6rem 0'}}>      
                          <div className="flexBetweenBar">
                          <Title level={2}>Tasks management</Title>
                                 <Button type="primary"   size="large" icon={<PlusOutlined />} onClick={()=>{history.push('/createTask')}}>Create new task</Button>
                          </div>
                          <Text  className="count_total">Total tasks: {count}</Text>
                      </Content> 
                      <Divider />
                      <Content style={{ margin: '2rem 1rem 0 0' }}>   
                        <div className="task_table">                        
                          <Table columns={columns} dataSource={data} pagination={false}  scroll={{ y: 600 }}/>
                        </div>
                      </Content>
                      {/* <Divider /> */}
                      <Content style={{ margin: '.6rem 0',padding:'0 0 1.8rem 0' }}> 
                      </Content> 
                    </Content>
                </div>
                </Content>
                </Spin> 
            </Layout>
            </Layout>
     
        </>
        
    )
}