import { createSlice, nanoid, createAsyncThunk } from '@reduxjs/toolkit'
import { post} from '../../api/axios'
import {  getEndpoint} from '../../api/config'
import { message } from 'antd'

const initialState = {
  categories: [],
  industries:[],
  status: 'idle',
  FirstIndustryId: '',
  error: false
}

export const fetchIndustryList = createAsyncThunk('categories/fetchIndustryList', async (params) => {
  const response = await post(getEndpoint('getIndustryList'))
  //return response.data
  return response.data;
})
export const fetchCategoriesList = createAsyncThunk('categories/fetchCategoriesList', async (params) => {
  const response = await post(getEndpoint('getCategoriesList') , params);
  return response.data;
})
export const saveCategories = createAsyncThunk('categories/saveCategories', async (params) => {
  let user = JSON.parse(window.localStorage.getItem('access-user'));
  const newParams = {
    "account": user.account,
    "dataList": params.categories,
    "industryId": parseInt(params.industryId)
  }
  
  const response = await post(getEndpoint('saveCategories') , newParams);
  return response.data;
})

export const fetchCanRemove= createAsyncThunk('categories/fetchCanRemove', async (params) => {
  const response = await post(getEndpoint('fetchCanRemove'),params)
  return response.data;
})

const categoriesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    categorieAdded: {
      reducer(state, action) {
        state.categories.push(action.payload)
        
      },
      prepare(data) {
        return {
          payload: 
          {
            key: nanoid(),
            industryId: data.industryId ,
            categoryTitle: data.categoryTitle,
            isNew : true       
          }}
      },
    },
    categorieEdit: {
      reducer(state, action) {
        state.categories = action.payload;
      },
      prepare(data) {
        return {
          payload : data //array
        }
      },
    },
    categorieRemove: {
      reducer(state, action) {
        state.categories = action.payload;
      },
      prepare(data) {
        return {
          payload : data //array
        }
      },
    },
    categorieMoveRow: {
      reducer(state, action) {
        state.categories = action.payload;
      },
      prepare(data) {
        return {
          payload : data //array
        }
      },
    },
    getCategoriesList: {
      reducer(state, action) {
        state.categories = action.payload;
      }
    },
  },
  extraReducers: {
    [fetchIndustryList.pending]: (state, action) => {
      state.status = 'loading'
    },
    [fetchIndustryList.fulfilled]: (state, action) => {
      state.status = 'succeeded'
      if(action.payload && parseInt(action.payload.code) === 0){
        if(action.payload.data && action.payload.data.dataList){
          state.industries = action.payload.data.dataList;
          state.FirstIndustryId = action.payload.data.dataList[0].industryId;
        }
        // else{
        //   state.industries = state.industries;
        //   state.FirstIndustryId = action.payload.data.dataList[0].industryId;
        // }
      }else{
        message.error('Network problems');
      }
   
      
    },
    [fetchIndustryList.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.error.message
    },
    [fetchCategoriesList.pending]: (state, action) => {
      state.status = 'loading'
    },
    [fetchCategoriesList.fulfilled]: (state, action) => {
      state.status = 'succeeded'
      if(action.payload){
        state.categories = action.payload.data.dataList?action.payload.data.dataList:[];
      }
      // else{
      //   state.categories =  state.categories;
      // }
    },
    [fetchCategoriesList.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.error.message
    },
    [saveCategories.fulfilled]: (state, action) => {
      // We can directly add the new post object to our posts array
      state.status = 'succeeded'
    },
    [saveCategories.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.error.message
    },
  }
})
export const { categorieAdded, categorieEdit, categorieRemove, categorieMoveRow } = categoriesSlice.actions

export default categoriesSlice.reducer

export const selectAllCategories = state => state.categories
export const selectIndustries = state => state.industries

export const selectIndustryId = state => state.FirstIndustryId