import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import {useHistory } from 'react-router-dom'
import { fetchPwdConfirm } from './../loginSlice'
import { Button ,Form, Input, Spin } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import {showErrorModal} from './../../../components/Modal'
import '../login.scss';
export const LoginComfirm = () => {
    const [pwd, setPwd] = useState('');// eslint-disable-line no-unused-vars
    const onPwdChanged = e => setPwd(e.target.value);
    const dispatch = useDispatch();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const onFinish = (values) => {
      setIsLoading(true);
      dispatch(fetchPwdConfirm(values)).then(res => {
        setIsLoading(false);
        if(res.payload && parseInt(res.payload.code)=== 0){
           history.push('/');
        }else if(res.payload && parseInt(res.payload.code)=== 1001){//same password does not modify
          const config = {
            title: (<><span style={{"fontWeight":"bold"}} className='font-color-error' >Cannot use the same password</span></>),
            content: 'For security reasons, you are required to change your password.',
          }
          showErrorModal(config);
       }else{
          const config = {
            title: (<><span style={{"fontWeight":"bold"}} className='font-color-error' >Error Message</span></>),
            content: 'Modify Password failed. Contact the administrator.',
          }
          showErrorModal(config);
       }
      });
    };
    const onFinishFailed = (errorInfo) => {
      console.log('Failed:', errorInfo);
    }

    return(
        <div className="login login_bg confirm">
          
            <Content>
            <div className="login_wrapper">
              <Content>
                <div className="logo_title">
                  <div className="title"><div className="logo"></div></div>
                  <div className="subtitle">CMS for WeChat mini program</div>
                  <div className="tip">Welcome to the CMS, please set your password.</div>
                  <div className="desciption">Password should between 8-30 characters. Must contain at least one capital letter, one small letter, one number and one special character.</div>
                </div>
              </Content>
              <Spin spinning={isLoading} delay={500}>
              <Form
                    name="basic"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    layout="vertical"
                  >
                    <Form.Item
                        name="password"
                        label="New password"
                        rules={[
                          {
                            required: true,
                            message: 'Please set new password before proceed.',
                            
                          },
                          {
                            message: 'Password should between 8-30 characters. Must contain at least one capital letter, one small letter, one number and one special character.',
                            pattern: new RegExp(/^(?=.{8,16})(?=.*[0-9])(?=.*[A-Z]{1,})(?=.*[a-z]{1,})(?=.*[!@#$%^&*?\{\}\\\/_\>\|.,:";'-=+])[\w!@#$%^&*?\{\}\\\/_\>\|.,:";'-=+]{8,16}$/)
                          },
                          
                        ]}
                        hasFeedback
                      >
                        <Input.Password placeholder="Input password here"  onChange={onPwdChanged}/>
                      </Form.Item>

                      <Form.Item
                        name="confirm"
                        label="Confirm Password"
                        dependencies={['password']}
                        hasFeedback
                        rules={[
                          {
                            required: true,
                            message: 'Please input your password.'
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (!value || getFieldValue('password') === value) {
                                return Promise.resolve();
                              }
                              return Promise.reject(new Error('The password is not match, please re-enter.'));
                            },
                          }),
                        ]}
                      >
                        <Input.Password  placeholder="Input password here"/>
                      </Form.Item>

                    <Form.Item >
                      <div className="login_btn_wrapper">
                      <Button type="primary" className="login_btn" htmlType="submit">
                        Confirm
                      </Button>
                      </div>
                    </Form.Item>
                  </Form>
                  </Spin>
                  </div>
              </Content>
        </div>
       
    )
}