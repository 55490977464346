import axios from 'axios';
import {TIMEOUT ,BASE_URL } from './config'
import { message } from 'antd';

//let token = 'Bearer 7fa1438cb488ba2de5fc4d9c90698521d8f4f363134249457486c6dff62';
let token = '';
const instance = axios.create({
    baseURL:BASE_URL,
    timeout:TIMEOUT,
    headers: {
        'Content-Type': 'content-type:application/json;charset=utf-8'
    },
})

instance.interceptors.request.use(config => {
    let user = JSON.parse(window.localStorage.getItem('access-user'));
    //当存有用户信息
    if (user) {
      token = user.token;
    }
    config.headers.Authorization = token;
    return config
},error => {

})

instance.interceptors.response.use(response => {
    if (response.data && response.data.code) {
        if (parseInt(response.data.code) === 401 ) {
            //未登录
            response.data.msg = "登录信息已失效，请重新登录";
            window.location.href="/login";
        }
        if (parseInt(response.data.code) === 1000) {
            message.error("Fatal  error: Please contact to administrator");
        }
    }
    return response
},error => {
    if(error.response){
      // 失败响应的status需要在response中获得
      console.log(error.response)
      switch(error.response.status){
        // 对得到的状态码的处理，具体的设置视自己的情况而定
        case 401:
            window.location.href="/login";
            break
        case 404:
            window.location.href="/404";
            break
        case 405:
            window.location.href="/login";
            break
         case 500:
            window.location.href="/500";
            break
        default:
            window.location.href="/404";
            break
      }
    }
    // 注意这里应该return promise.reject(),
    // 因为如果直接return err则在调用此实例时，响应失败了也会进入then(res=>{})而不是reject或catch方法
    return Promise.reject(error);
})
export const get = (url, params, responseType)=>{
    return new Promise((resolve,reject) => {
        instance({
            url:url,
            method:'get',
            params:params,
            responseType:responseType? responseType: '',
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        })
    })
}

export const post = (url, params ,headers) =>{
    return new Promise((resolve,reject) => {
        instance({
            url:url,
            method:'post',
            data:params,
            headers: headers? headers: {},
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        })
    })

}