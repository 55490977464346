import { configureStore } from '@reduxjs/toolkit'


import loginReducer from '../features/login/loginSlice'
import categoriesReducer from '../features/categories/categoriesSlice'
import promotionalReducer from '../features/home/promotionalSlice'
import taskReducer from '../features/task/taskSlice'

export default configureStore({
  reducer: {
    login: loginReducer,
    categories: categoriesReducer,
    industries: categoriesReducer,
    fileList: promotionalReducer,
    tasks: taskReducer,
    segmentList: taskReducer,
    categoryList: taskReducer,
    taskInfo: taskReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
  }),
  
})