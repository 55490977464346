import React, { memo, useState } from 'react';
import { arrayMove, SortableContainer, SortableElement } from 'react-sortable-hoc';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import './pictureGrid.scss';
import { imagePreview, getBase64Img } from '../util/pictureUtil';
import UploadList from 'antd/es/upload/UploadList';
import { Modal, Upload  } from 'antd';
import {showErrorModal,} from './../../components/Modal'




const { confirm } = Modal;
const itemStyle = {
  width: 104,
  height: 104,
  margin: 4,
  cursor: 'grab'
};


const SortableItem = SortableElement((params) => (
  <div style={itemStyle}>
    <UploadList
      locale={{ previewFile: 'Preview', removeFile: 'Delete'}}
      showDownloadIcon={false}
      listType={params.props.listType}
      onPreview={params.onPreview}
      onRemove={params.onRemove}
      items={[params.item]}
    />    
  </div>
  
));


const listStyle = {
  display: 'flex',
  flexWrap: 'wrap',
  maxWidth: '100%',
};
const SortableList = SortableContainer((params) => { 
  const beforeUpload = (file) =>{
    const arry = file.name.split('.');
    const typeImage = arry[arry.length-1]
    const isJpgOrPng = (file.type === 'image/jpeg' && typeImage === 'jpg') || file.type === 'image/png';
    if (!isJpgOrPng) {
      const config = {
        title: (<><span style={{"fontWeight":"bold"}} className='font-color-error' >File format Error</span></>),
        content: 'File format not supported.',
      }
      showErrorModal(config);
      return Upload.LIST_IGNORE;
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      const config = {
        title: (<><span style={{"fontWeight":"bold"}} className='font-color-error' >File Size Error</span></>),
        content: 'File is too large to upload. Max.2mb.',
      }
      showErrorModal(config);
      return  Upload.LIST_IGNORE;;
    }
    return false;
    
  }
  return (
    <div style={listStyle}>
      {params.items.map((item, index) => (
        <div key={index}>
          <SortableItem
            key={`${item.uid}`}
            index={index}
            item={item}
            props={params.props}
            onPreview={params.onPreview}
            onRemove={params.onRemove}
          />
        </div>
      ))}
     
      <div style={{width:'100%'}} className='font-color-tip'>Drag to change banner image sequence</div>
      <Upload
        {...params.props}
        showUploadList={false}
        onChange={params.onChange}
        beforeUpload = {beforeUpload}
      >
        {params.props.children}        
      
      </Upload>
      
    </div>
  );
});
const PicturesGrid = memo(({ onChange: onFileChange, ...props }) => {
  const [previewImage, setPreviewImage] = useState('');
  const fileList = props.fileList || [];
  const onSortEnd = ({ oldIndex, newIndex }) => {
    onFileChange({ fileList: arrayMove(fileList, oldIndex, newIndex) });
  };

  const onChange = ({ fileList: newFileList }) => { 
    getBase64Img(newFileList[newFileList.length - 1].originFileObj, imageUrl =>{
      const newObject = {oaTaskUrl:'',isNew: true ,imgId:'',thumbUrl:imageUrl};
      const newFile = {...newFileList[newFileList.length - 1],...newObject}
      newFileList[newFileList.length - 1] = newFile;
      onFileChange({ fileList: newFileList });
    });

   
  };

  const onRemove = (file) => {
    if(fileList.length === 1){  
      Modal.error({
        title: (<><span style={{"fontWeight":"bold"}} className='font-color-error' >Cannot delete this image</span></>),
        content: 'At least one image for hero banner is required. Please try upload one more and delete the old one.',
      });
      return false;    
    }
    confirm({
      title: (<><span style={{"fontWeight":"bold"}} className='font-color-error' >Sure about delete this?</span></>),
      icon:  (<ExclamationCircleOutlined className='font-color-error'/>),
      content: 'Once it’s deleted, it’s gone for good.You cannot undo this action.',
      onOk() {
        const newFileList = fileList.filter(
          (item) => item.uid !== file.uid
        );
        onFileChange({ fileList: newFileList });
      },
      onCancel() {
        console.log('Cancel');
      },
    });
    
  };

  const onPreview = async (file) => {
    await imagePreview(file, ({ image }) => {
      setPreviewImage(image);
    });
  };
  
  return (
    <>
      <SortableList
        // 当移动 1 之后再触发排序事件，默认是0，会导致无法触发图片的预览和删除事件
        distance={1}
        items={fileList}
        onSortEnd={onSortEnd}
        axis="xy"
        helperClass="SortableHelper"
        props={props}
        onChange={onChange}
        onRemove={onRemove}
        onPreview={onPreview}
      />
      <Modal
        visible={!!previewImage}
        footer={null}
        onCancel={() => setPreviewImage('')}
        bodyStyle={{ padding: 0 }}
      >
        <img style={{ width: '100%' }} alt="" src={previewImage} />
      </Modal>
      
    </>
  );
});

export { PicturesGrid };