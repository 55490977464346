import { Modal  } from 'antd'

export const showErrorModal = (config) => {
    const defaultConfig = {
        style: {top:260},
        width: '392px',
        okText : 'OK'
    }
    config = {...defaultConfig,...config}
    Modal.error(config);
}

export const showModal = (config) => {
    const defaultConfig = {
        style: {top:260},
        width: '392px',
        okText : 'OK'
    }
    config = {...defaultConfig,...config}
    Modal.info(config);
}

export const showWarningModal = (config) => {
    const defaultConfig = {
        style: {top:260},
        width: '392px',
        okText : 'OK'
    }
    config = {...defaultConfig,...config}
    Modal.warning(config);
}

export const showSuccessModal = (config) => {
    const defaultConfig = {
        style: {top:260},
        width: '392px',
        okText : 'OK'
    }
    config = {...defaultConfig,...config}
    Modal.success(config);
}
