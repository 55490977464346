

import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button ,Input, Form, Layout, Divider, Typography, message, Modal, Spin} from 'antd'
import { useHistory } from 'react-router-dom'
// import defaultFileList from '../util/defaultFileList'
import { PicturesGrid } from '../sortable/PicturesGrid'
import { UploadOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import {SideMenu} from '../../components/Menus';
import { fetchFileList, changeFileListyStatus, saveFileList } from './../home/promotionalSlice'
import './home.scss';
import { showErrorModal, showSuccessModal } from '../../components/Modal'
const { Content } = Layout;
const { Title } = Typography;
function Home() {
  const { confirm } = Modal;
  // const [key, setKey] = useState('')
  // const onKeyChanged = e => setKey(e.target.value)
  const history = useHistory();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const fileListyStatus = useSelector(state => state.fileList.status);
  // const [fileList, setFileList] = useState(defaultFileList);
  const [fileList, setFileList] = useState([]);
  const [addRequestStatus, setAddRequestStatus] = useState('idle')
  const [isEditStatus, setEditStatus] = useState(false)
  const canSave = addRequestStatus === 'idle' && !isEditStatus;
  // const [canSubmit,setCanSubmit] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (fileListyStatus === 'idle') {
        getFileList();
    }
    return() => {
      dispatch(changeFileListyStatus());
    }
  },[])// eslint-disable-next-line react-hooks/exhaustive-deps

  const getFileList = async() => {
   await dispatch(fetchFileList()).then(res => {
      if(res.payload.code === 0){
        if(res.payload.data.dataList){
          setFileList(res.payload.data.dataList);
        }
      }else{
        const config = {
          title:'Error promotional banner',
          content: 'Cannot get promotional banner.'
        }
        showErrorModal(config);
      }
    });
    setIsLoading(false);
  }
  const handleChange = ({ fileList }) =>{ 
    setEditStatus(true); 
    setFileList(fileList);
    let filesValue = {};
    for(let i = 0;i < fileList.length; i++){
       let item = fileList[i].oaTaskUrl;
       let key = 'taskurl' + i;
       filesValue[key] = item;
    }
    console.log(filesValue);
    form.setFieldsValue(filesValue);
    form.validateFields();
  }
  const uploadButton = (
    <div>
      <UploadOutlined />
      <div>Upload image here</div>
    </div>
  );
  const InputTaskUrl = (file) => {
    const input_value = file.target.value;
    const input_index = file.target.attributes.index.value;
    let newlist = [...fileList];
    newlist = newlist.map((item, idx) => idx === parseInt(input_index) ? {...item, oaTaskUrl:input_value } : item)

    setFileList([]);
    setFileList([...newlist]);
    setEditStatus(true);
  };

   const onFinish = async() => {
    try {
        setIsLoading(true);
        setAddRequestStatus('pending');
        await dispatch(saveFileList({fileList: fileList})).then(res => {
          if(res.payload.code === 0){
            const config = {
              title:'Published successfully',
              content: 'Updates published successfully.'
            }
            showSuccessModal(config);
          }else{
            const config = {
              title:'Error promotional banner',
              content: 'Cannot get promotional banner.'
            }
            showErrorModal(config);
          }
      })//.then(getFileList());
      //refresh the list
      setIsLoading(false);
    } catch (err) {
      console.error('Failed to save the post: ', err)
      setIsLoading(false);
    } finally {
      setEditStatus(false);
      setAddRequestStatus('idle');
      setIsLoading(false);
    };
    //message.success('Submit success!');
  };

  const onFinishFailed = () => {
    message.error('Submit failed!');

    
  };
  const handleCancel = () => {
    confirm({
      title: (<><span style={{"fontWeight":"bold"}} className='font-color-info' >You have unsaved changes</span></>),
      icon: <ExclamationCircleOutlined className='font-color-info'/>,
      content: 'You have edits on this task, which action you want to proceed?',
      okText: 'continue editing',
      cancelText: 'cancel & discard',
      onOk() {
        console.log('Continue');
      },
      onCancel() {
        dispatch(fetchFileList()).then(res => {
          if(res.payload.code === 0){
            if(res.payload.data.dataList){
              const newFileList = res.payload.data.dataList;
              setEditStatus(false);
              setAddRequestStatus('idle');
              history.push('/');
              handleChange({ fileList: newFileList });
            }
          }else{
            const config = {
              title:'Error promotional banner',
              content: 'Cannot get promotional banner.'
            }
            showErrorModal(config);
          }
        });
     
      },
    });
  
  }
  const submitFrom = async () => {
      form.submit();
     
  }
  const imageList = !fileList?  [] : fileList.map((file,index) => (
      <div key={file.bannerUuid+ '__' + index} className="taskurl_list"  style={{ padding: '10px 10px 10px 0' }}>        
        <span className="taskurl_lable"> Banner - {index+1}</span> 
        <span className="taskurl_input">
            <Form.Item name = {'taskurl'+index} 
                       initialValue={file.oaTaskUrl}
                       key={file.bannerUuid + '_' + index}
                       rules={[{pattern: new RegExp(/(http|https):\/\/\S*/), message:'Please input valid URL address'} ]}>
              <Input size="large" index={index}  placeholder='Http://' onChange={InputTaskUrl}/>
            </Form.Item>
        </span> 
      </div>
  ))



  return (
    <>
       <Layout>
        <SideMenu
            index='1'
          >
        </SideMenu>
        <Layout>
        <Spin
        wrapperClassName="self-spin-wrapper"
        size="large"
        spinning={isLoading}
      > 
          <Content style={{ padding: '1.5rem' }}>         
            <div className="site-layout-background content-div promotional">
              <Content style={{ margin: '24px 16px 0' }}>  
                <Content style={{ margin: '10px 0'}}>      
                  <div className="flexBetweenBar">
                    <Title level={2}>Home page promotional banner</Title>
                        {canSave ? <Button type="primary"  disabled  size="large">Confirm and publish</Button>
                                  : <Button type="primary"  onClick={submitFrom}    size="large">Confirm and publish</Button>
                        }
                  </div>
                </Content> 
                <Divider />
                <Title level={4}>Manage image</Title>
                <div className="img_list">
                    <PicturesGrid
                      action=''
                      listType="picture-card"
                      fileList={fileList}
                      onChange={handleChange}
                      accept="image/png,.jpg"
                    >
                      {fileList.length >= 5 ? null : uploadButton}
                    </PicturesGrid>
                </div>
                <div  className='font-color-tip'>At least 1 image is required and max 5 images to be inputted for hero banner</div>
                <div  className='font-color-tip'>Supported file format: 5:4 ratio, JPG and PNG, Min. res. 750(W) by 600(H), Max. 2mb</div>
                <Title level={4}>Official account task URL</Title>
                <Content style={{ margin: '10px 16px 0 0' }}>  
                  <Form form={form} onFinish={onFinish}  onFinishFailed={onFinishFailed}  autoComplete="off"
                  >
                    {imageList}
                  </Form>
                </Content>
                <Divider />
                <Content style={{ margin: '10px 0',padding:'0 0 30px 0' }}>         
                    <div className="flexBetweenBar">
                      {canSave ? <Button type="primary"  disabled  size="large">Confirm and publish</Button>
                                : <Button type="primary"  onClick={submitFrom}    size="large">Confirm and publish</Button>
                      }
                      {canSave ? <Button size="large" disabled>Cancel and discard</Button>
                                : <Button size="large"  onClick={handleCancel} >Cancel and discard</Button>
                      }
                     
                      
                    </div>
                </Content> 
              </Content>
            </div>
          </Content>
          </Spin> 
        </Layout>
      </Layout>
  </>
  );
}

export default Home;